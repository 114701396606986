import Video from "../../assets/images/video.png";

const About = ({ t }) => {
  return (
    <div
      className="container bg-white p-20 flex mx-auto items-center max-[1280px]:flex-col-reverse max-sm:px-10"
      id="about-ayodhya"
    >
      <div className="max-[1280px]:text-center">
        <h1 className="text-dark-brown font-bold text-5xl mb-10 max-[767px]:text-4xl">
          {t("ABOUT")}
        </h1>
        <div className="w-4/5 text-light-brown max-[1280px]:mx-auto max-[767px]:w-auto tracking-wider">
          <p className="text-base">{t("ABOUT_1")}</p>
          <p className="text-base mt-5">{t("ABOUT_3")}</p>
          <p className="text-base mt-5">
            <b className="tracking-widest text-2xl">{t("PLACE_TO_VISIT")}</b>
            <div className="grid grid-rows-8 grid-flow-col gap-4 mt-5 max-[559px]:grid-rows-9 max-[401px]:grid-flow-row">
              <p>{t("RAM_MANDIR")}</p>
              <p>{t("SURAJ_KUND")}</p>
              <p>{t("CHHAPAIYA")}</p>
              <p>{t("SARYU_GHAT")}</p>
              <p>{t("SARYU_AARTI")}</p>
              <p>{t("LAKSHMAN_KILLA")}</p>
              <p>{t("HANUMANGHARI")}</p>
              <p>{t("GUPTAR_GHAT")}</p>
              <p>{t("MANI_PARVAT")}</p>
              <p>{t("DASHRATH_MAHAL")}</p>
              <p>{t("KALENATH")}</p>
              <p>{t("RAM_KI_PALDI")}</p>
              <p>{t("RANG_MAHAL_MANDIR")}</p>
              <p>{t("SITA_KI_RASOI")}</p>
              <p>{t("RAJ_DWAR")}</p>
              <p>{t("TRETA_KE_THAKUR")}</p>
              <p>{t("KANAK_BHAWAN")}</p>
              <p>{t("NAGESHWARA_NATH_TEMPLE")}</p>
              <p>{t("MANIRAM_DAS_CHHAWANI")}</p>
              <p>{t("SITARAJ_MAHAL_MANDIR")}</p>
              <p>{t("DEOKALI_KUND")}</p>
              <p>{t("RAM_PATH")}</p>
              <p>{t("LATA_MANGESHKAR_CHAUK")}</p>
            </div>
          </p>
        </div>
      </div>
      <img src={Video} alt="Video" className="max-[1280px]:mb-10" />
    </div>
  );
};

export default About;
