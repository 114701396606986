import { Route, Routes } from "react-router-dom";
import { routeArray } from "./routeArray";

const Router = () => {
  return (
    <>
      <Routes>
        {routeArray?.map(({ path, component }, index) => (
          <Route key={index} path={path} exact={true} element={component} />
        ))}
      </Routes>
    </>
  );
};

export default Router;
