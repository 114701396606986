import React, { useEffect } from "react";
import { STATIC_DATA, STATIC_TYPE } from "../../helper/constant";
import StaticPageWrapper from "./StaticPageWrapper";

const StaticPage = ({ type }) => {
  useEffect(() => {
    const element = document.documentElement;
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  }, []);
  return (
    <>
      <StaticPageWrapper>
        <div className="row px-8 py-4 bg-varden text-brown">
          <div className="text-[35px] tracking-wide">
            {type === STATIC_TYPE.privacy
              ? "Privacy Policy"
              : "Terms & Conditions"}{" "}
            – MARGKGB TECHNOLOGIES PVT. LTD.
          </div>
        </div>
        <div className="py-4 px-8 tb:py-[96px] tb:px-[140px] text-brown">
          <div>
            <div
              className="my-2 text-[20px] text-gray-400   "
              dangerouslySetInnerHTML={{ __html: STATIC_DATA[type] }}
            ></div>
          </div>
        </div>
      </StaticPageWrapper>
    </>
  );
};

export default StaticPage;
