const Twitter = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        id="Search results for Twitter - Flaticon-12 1"
        clip-path="url(#clip0_175_501)"
      >
        <g id="Group">
          <path
            id="Vector"
            d="M11.8146 8.50692L18.9178 0.25H17.2346L11.0669 7.41937L6.14071 0.25H0.458984L7.90828 11.0914L0.458984 19.75H2.14232L8.65559 12.1789L13.858 19.75H19.5397L11.8142 8.50692H11.8146ZM9.50904 11.1869L8.75427 10.1073L2.74884 1.51718H5.33434L10.1808 8.44969L10.9356 9.52925L17.2354 18.5404H14.6499L9.50904 11.1873V11.1869Z"
            fill={!!props.fill ? props.fill : "white"}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_175_501">
          <rect
            width="19.5"
            height="19.5"
            fill="white"
            transform="translate(0.25 0.25)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Twitter;
