import HeaderLogo from "../../assets/images/Logo.svg";
import HamBurger from "../../assets/images/Hamburger.png";
import Close from "../../assets/images/Close.png";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { LANGUAGE } from "../../helper/constant";

const Header = () => {
  const ref = useRef();
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const [isShow, setIsShow] = useState(false);
  const navArr = [
    {
      name: t("HOME"),
      isWeb: true,
    },
    {
      name: t("OUR_SERVICE"),
      isWeb: true,
      id: "our-services",
    },
    {
      name: t("ABOUT"),
      isWeb: true,
      id: "about-ayodhya",
    },
    {
      name: t("DOWNLOAD"),
      isWeb: true,
      id: "Download",
    },
    {
      name: t("CONTACT_US"),
      isWeb: true,
      id: "contact-us",
    },
    {
      name: "Terms & Condition",
      isWeb: false,
    },
    {
      name: "Privacy Policy",
      isWeb: false,
    },
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsShow(false);
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener("click", handleClickOutside);

    // Detach the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const scrollToContent = (id) => {
    if (id) {
      isShow && setIsShow(!isShow);
      const element = document.getElementById(id);
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = element.getBoundingClientRect().top;
      const elementPosition = elementRect - bodyRect;

      window.scrollTo({
        top: elementPosition,
        behavior: "smooth",
      });
    }
  };

  const handleLanguage = () => {
    if (currentLanguage === LANGUAGE.en) {
      i18n.changeLanguage("hi");
      setCurrentLanguage("hi");
    } else {
      i18n.changeLanguage("en");
      setCurrentLanguage("en");
    }
  };

  return (
    <div ref={ref}>
      <div className="flex bg-varden p-4 px-7 rounded-[10px] justify-between items-center max-[850px]:rounded-none max-[850px]:px-5 max-[850px]:h-[7vh] max-[850px]:border-b max-[850px]:border-brown">
        <img src={HeaderLogo} alt="Header Logo" className="w-24" />
        <div className="flex justify-around w-[700px] max-lg:w-[600px] max-[850px]:hidden items-center">
          {navArr.map(
            (val) =>
              val.isWeb && (
                <p
                  className="cursor-pointer"
                  onClick={() => scrollToContent(val.id)}
                >
                  {val.name}
                </p>
              )
          )}
          <p
            className="cursor-pointer bg-saffron py-1 rounded w-16 text-center"
            onClick={handleLanguage}
          >
            {LANGUAGE[currentLanguage] === "hi" ? "ENG" : "HINDI"}
          </p>
        </div>
        <div className="hidden max-[850px]:flex">
          <p
            className="cursor-pointer bg-dark-brown py-1 rounded w-16 text-center text-white"
            onClick={handleLanguage}
          >
            {LANGUAGE[currentLanguage] === "hi" ? "ENG" : "HINDI"}
          </p>
          <img
            src={isShow ? Close : HamBurger}
            alt="HamBurger"
            onClick={() => {
              setIsShow(!isShow);
            }}
            className="ease-in-out ml-3"
          />
        </div>
      </div>
      {isShow && (
        <div className="hidden max-[850px]:block h-[93vh] ease-in-out z-10 w-screen bg-varden absolute">
          <div className="flex flex-col justify-between h-full pb-6">
            <div className="flex flex-col justify-center items-center pt-5 text-brown px-10">
              {navArr.map((val) => (
                <p
                  className="border-b border-[#C2BFBA] w-full text-center py-3 cursor-pointer"
                  onClick={() => {
                    scrollToContent(val.id);
                  }}
                >
                  {val.name}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
