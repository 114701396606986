const Card = ({ title, description, image }) => {
  return (
    <div className="bg-white rounded-xl pt-5 px-4 pb-12 min-w-64 shadow-xl min-h-56">
      <img
        src={image}
        alt={title}
        className="p-2 bg-light-yellow rounded-xl mb-4"
      />
      <p className="text-dark-brown font-semibold mb-2">{title}</p>
      <p className="w-48">{description}</p>
    </div>
  );
};

export default Card;
