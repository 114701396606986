import SnapChat from "../../assets/icons/SnapChat";
import Instagram from "../../assets/icons/Instagram";
import Reddit from "../../assets/icons/Reddit";
import Pinterest from "../../assets/icons/Pinterest";
import FaceBook from "../../assets/icons/FaceBook";
import Linkedin from "../../assets/icons/Linkedin";
import Twitter from "../../assets/icons/Twitter";
import YouTube from "../../assets/icons/YouTube";
import { Link } from "react-router-dom";

const Footer = () => {
  const linkObj = {
    snapChat: "https://accounts.snapchat.com/v2/welcome",
    reddit: "https://www.reddit.com/user/margkgb22122023/",
    pinterest: "https://pin.it/cPEwColeF",
    facebook: "https://www.facebook.com/profile.php?id=61554553110109",
    linkedin:
      "https://www.linkedin.com/feed/?trk=guest_homepage-basic_google-one-tap-submit",
    twitter: "https://twitter.com/marg_kgb",
    instagram: "https://www.instagram.com/marg.kgb/",
    youtube: "https://www.youtube.com/channel/UCLMzlAA95Oe6eTeCM-BU9Ig",
    // sharechat: "https://sharechat.com/profile/1890951760?d=n",
  };

  const handleClick = (name) => {
    const url = linkObj[name];
    window.open(url, "_blank");
  };

  return (
    <div className="bg-harmonies flex py-5 px-36 text-white justify-between  max-[1215px]:flex-col-reverse max-xl:flex-col max-xl:items-center max-xl:space-y-4 max-[620px]:px-10">
      <p className="max-[1215px]:hidden">
        <Link to="/tnc">Terms & Conditions</Link> |{" "}
        <Link to="/privacy">Privacy Policy</Link>{" "}
      </p>
      <p className="max-[1215px]:m-0 max-[1215px]:text-center">
        © Copyright 2024 MARGKGB TECHNOLOGIES PVT.LTD. All rights reserved
      </p>
      <div className="flex space-x-4 h-6 max-[1215px]:mb-2">
        <SnapChat
          className="cursor-pointer"
          onClick={() => handleClick("snapChat")}
        />
        <Reddit
          className="cursor-pointer"
          onClick={() => handleClick("reddit")}
        />
        <Pinterest
          className="cursor-pointer"
          onClick={() => handleClick("pinterest")}
        />
        <FaceBook
          className="cursor-pointer"
          onClick={() => handleClick("facebook")}
        />
        <Linkedin
          className="cursor-pointer"
          onClick={() => handleClick("linkedin")}
        />
        <Twitter
          className="cursor-pointer"
          onClick={() => handleClick("twitter")}
        />
        <Instagram
          className="cursor-pointer"
          onClick={() => handleClick("instagram")}
        />
        <YouTube
          className="cursor-pointer"
          onClick={() => handleClick("youtube")}
        />
        {/* <YouTube
          className="cursor-pointer"
          onClick={() => handleClick("sharechat")}
        /> */}
      </div>
    </div>
  );
};

export default Footer;
