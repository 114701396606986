import Car from "../../assets/images/Car.png";
import Temple from "../../assets/images/TempleIcon.png";
import Mala from "../../assets/images/Mala.png";
import Fork from "../../assets/images/ForkKnife.png";
import Card from "../common/Card";

const Services = ({ t }) => {
  const cardData = [
    {
      image: Car,
      title: t("TRANSPORT"),
      description: t("TRANSPORT_TYPE"),
    },
    {
      image: Temple,
      title: t("DARSHAN"),
      description: t("DARSHAN_TYPE"),
    },
    {
      image: Fork,
      title: t("FOOD"),
      description: t("FOOD_TYPE"),
    },
    {
      image: Mala,
      title: t("SHOPPING"),
      description: t("SHOPPING_TYPE"),
    },
  ];
  return (
    <div className="bg-varden py-20" id="our-services">
      <div className="container mx-auto">
        <div className="text-center mb-10">
          <h1 className="text-dark-brown font-bold text-5xl max-[767px]:text-4xl">
            {t("OUR_SERVICE")}
          </h1>
          <p className="text-dark-brown font-bold text-xl mt-4 w-4/5 mx-auto leading-7 tracking-wider">
            {t("OUR_SERVICE_TAG")}
          </p>
          <p className="text-dark-brown mt-0 w-4/5 mx-auto leading-7 tracking-wider">
            {t("OUR_SERVICE_DESCRIPTION")}
          </p>
        </div>
        <div className="grid grid-cols-4 w-fit m-auto max-xl:grid-cols-2 max-sm:grid-cols-1">
          {cardData?.map((data) => (
            <div className="p-3">
              <Card
                title={data.title}
                image={data.image}
                description={data.description}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
