import { STATIC_TYPE } from "../../helper/constant";
import Home from "../../view/Home";
import StaticPage from "../Static";

export const routeArray = [
    {
        path:"/",
        component: <Home/>
    },
    {
        path:"/privacy",
        component: <StaticPage type={STATIC_TYPE.privacy}/>
    },
    {
        path:"/tnc",
        component: <StaticPage type={STATIC_TYPE.tnc}/>
    }
]