import React from "react";
import CloseIcon from "../../../assets/images/XCircle.svg";
import clsx from "clsx";

const Modal = ({ isOpen, onClose, children, title, width }) => {
  return (
    <div
      className={`fixed inset-0 z-50 transition-opacity ${
        isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
      } `}
      onClick={onClose}
    >
      <div className="flex items-center justify-center min-h-screen p-4">
        <div className="fixed inset-0 bg-gray-800 opacity-75"></div>
        <div
          className={clsx(
            "bg-varden rounded-lg p-4 z-50 transition-transform transform  top-1/2 left-1/2 opacity-100"
          )}
        >
          <div className="flex justify-between mb-2">
            <div>
              <h1 className="text-xl font-semibold">{title && ""}</h1>
            </div>
            <button
              onClick={onClose}
              className="text-gray-700 hover:text-gray-900"
            >
              <img src={CloseIcon} alt="Close" />
            </button>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
