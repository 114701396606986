import Header from "../../component/Header";
import Temple from "../../assets/images/temple.png";
import Services from "../../component/Services";
import About from "../../component/About";
import Download from "../../component/Download";
import Footer from "../../component/Footer";
import ContactUs from "../../component/ContactUs";
import { useTranslation } from "react-i18next";
import Modal from "../../component/common/Modal";
import { useEffect, useState } from "react";
import Whatsapp from "../../assets/images/Whatsapp.svg";

const Home = () => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const isModalOpen = localStorage.getItem("IsModalOpen");
    if (!isModalOpen) {
      setIsOpen(true);
      localStorage.setItem("IsModalOpen", true);
    }
  }, []);

  const onClose = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 300);
  };

  const handleClick = () => {
    const url = "//api.whatsapp.com/send?phone=918200983800";
    window.open(url, "_blank");
  };
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <div className="text-center p-8 max-[622px]:p-5">
          <p className="light-brown text-3xl mb-4 tracking-wider max-[622px]:text-xl">
            {t("COMING_SOON")}
          </p>
          <p className="text-4xl text-dark-brown mb-4 tracking-wider font-bold max-[622px]:text-2xl">
            {t("MAHAKUMBH")}
          </p>
        </div>
      </Modal>
      <div className="bg-shree-ram bg-center bg-cover h-[100vh] max-h-[100vh] relative  max-[1024px]:h-[80vh] max-[800px]:max-h-[55vh]">
        <div className="pt-20 px-40 max-xl:px-20 max-lg:px-10 max-[850px]:p-0">
          <Header />
          <div className="mt-20  w-5/12 max-[1686px]:w-6/12 max-[1458px]:w-7/12 max-[1370px]:mt-10 max-[889px]:w-[70%] max-[600px]:w-[80%] max-[889px]:pl-8">
            <p className="text-white font-bold text-[45px] max-[1370px]:text-[40px] max-[1075px]:text-[35px] max-[800px]:text-[30px] max-[400px]:text-[25px]">
              {t("MARG_KGB")}
            </p>
            <p className="w-[80%] text-white mt-5 max-[750px]:text-[16px] max-[380px]:text-[12px] tracking-wide">
              {t("SECTION_1_META_1")}
            </p>
            <p className="w-[80%] text-white mt-3 max-[750px]:text-[16px] max-[380px]:text-[12px] tracking-wide">
              {t("SECTION_2_META_2")}
            </p>
            <div className="bg-harmonies rounded p-4 text-white mt-4 text-2xl tracking-wider w-fit max-[520px]:hidden">
              <p>{t("COMING_SOON")}</p>
              <p className="font-bold mt-3">{t("MAHAKUMBH")}</p>
            </div>
          </div>
        </div>
        <img
          src={Temple}
          alt="Temple"
          className="absolute bottom-0 right-0 max-h-[50%] max-[1050px]:max-h-[40%] max-[730px]:hidden"
        />
      </div>
      <Services t={t} />
      <About t={t} />
      <Download t={t} />
      <ContactUs t={t} />
      <Footer />
      <div
        className="fixed bottom-6  right-2 p-3 rounded-full cursor-pointer"
        onClick={handleClick}
      >
        <img src={Whatsapp} alt="Whatsapp" />
      </div>
    </>
  );
};

export default Home;
