import i18next from "i18next";
import { english } from "./englishLanguage";
import { hindi } from "./hindiLanguage";

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: "en", // default language
  fallbackLng: "en", // fallback language
  resources: {
    en: {
      translation: english, // English translations
    },
    hi: {
      translation: hindi, // Hindi translations
    },
  },
});

export default i18next;
