const Instagram = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="InstagramLogo" clip-path="url(#clip0_175_504)">
        <path
          id="Vector"
          d="M15 12C15 12.5933 14.8241 13.1734 14.4944 13.6667C14.1648 14.1601 13.6962 14.5446 13.1481 14.7716C12.5999 14.9987 11.9967 15.0581 11.4147 14.9424C10.8328 14.8266 10.2982 14.5409 9.87868 14.1213C9.45912 13.7018 9.1734 13.1672 9.05764 12.5853C8.94189 12.0033 9.0013 11.4001 9.22836 10.8519C9.45542 10.3038 9.83994 9.83524 10.3333 9.50559C10.8266 9.17595 11.4067 9 12 9C12.7956 9 13.5587 9.31607 14.1213 9.87868C14.6839 10.4413 15 11.2044 15 12ZM21.75 7.5V16.5C21.7485 17.8919 21.1949 19.2264 20.2107 20.2107C19.2264 21.1949 17.8919 21.7485 16.5 21.75H7.5C6.10807 21.7485 4.77358 21.1949 3.78933 20.2107C2.80509 19.2264 2.25149 17.8919 2.25 16.5V7.5C2.25149 6.10807 2.80509 4.77358 3.78933 3.78933C4.77358 2.80509 6.10807 2.25149 7.5 2.25H16.5C17.8919 2.25149 19.2264 2.80509 20.2107 3.78933C21.1949 4.77358 21.7485 6.10807 21.75 7.5ZM16.5 12C16.5 11.11 16.2361 10.24 15.7416 9.49993C15.2471 8.75991 14.5443 8.18314 13.7221 7.84254C12.8998 7.50195 11.995 7.41283 11.1221 7.58647C10.2492 7.7601 9.44736 8.18868 8.81802 8.81802C8.18868 9.44736 7.7601 10.2492 7.58647 11.1221C7.41283 11.995 7.50195 12.8998 7.84254 13.7221C8.18314 14.5443 8.75991 15.2471 9.49993 15.7416C10.24 16.2361 11.11 16.5 12 16.5C13.1931 16.4988 14.337 16.0243 15.1806 15.1806C16.0243 14.337 16.4988 13.1931 16.5 12ZM18.75 6.375C18.75 6.1525 18.684 5.93499 18.5604 5.74998C18.4368 5.56498 18.2611 5.42078 18.0555 5.33564C17.85 5.25049 17.6238 5.22821 17.4055 5.27162C17.1873 5.31502 16.9868 5.42217 16.8295 5.5795C16.6722 5.73684 16.565 5.93729 16.5216 6.15552C16.4782 6.37375 16.5005 6.59995 16.5856 6.80552C16.6708 7.01109 16.815 7.18679 17 7.3104C17.185 7.43402 17.4025 7.5 17.625 7.5C17.9234 7.5 18.2095 7.38147 18.4205 7.1705C18.6315 6.95952 18.75 6.67337 18.75 6.375Z"
          fill={!!props.fill ? props.fill : "white"}
        />
      </g>
      <defs>
        <clipPath id="clip0_175_504">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Instagram;
