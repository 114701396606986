const FaceBook = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="FacebookLogo" clip-path="url(#clip0_175_493)">
        <path
          id="Vector"
          d="M21.75 11.9999C21.7469 14.3828 20.8726 16.6824 19.2917 18.4655C17.7109 20.2485 15.5326 21.392 13.1671 21.6805C13.1145 21.6864 13.0611 21.6812 13.0107 21.665C12.9602 21.6489 12.9137 21.6222 12.8743 21.5868C12.8348 21.5513 12.8034 21.5079 12.782 21.4594C12.7605 21.411 12.7496 21.3585 12.75 21.3055V14.2499H15C15.1028 14.2501 15.2045 14.2292 15.2989 14.1884C15.3933 14.1476 15.4783 14.0879 15.5487 14.0129C15.619 13.9379 15.6732 13.8493 15.7078 13.7525C15.7424 13.6557 15.7568 13.5528 15.75 13.4502C15.7334 13.2573 15.6444 13.0779 15.5009 12.9479C15.3574 12.818 15.1701 12.7472 14.9765 12.7499H12.75V10.4999C12.75 10.102 12.908 9.7205 13.1893 9.43919C13.4706 9.15789 13.8521 8.99985 14.25 8.99985H15.75C15.8528 9.00008 15.9545 8.97916 16.0489 8.9384C16.1433 8.89764 16.2283 8.83791 16.2987 8.76292C16.369 8.68792 16.4232 8.59927 16.4578 8.50246C16.4924 8.40565 16.5068 8.30276 16.5 8.20017C16.4834 8.00698 16.3941 7.82727 16.2502 7.6973C16.1063 7.56733 15.9185 7.49677 15.7246 7.49985H14.25C13.4543 7.49985 12.6912 7.81592 12.1286 8.37853C11.566 8.94114 11.25 9.70421 11.25 10.4999V12.7499H8.99996C8.89714 12.7496 8.79538 12.7705 8.70099 12.8113C8.60659 12.8521 8.52159 12.9118 8.45126 12.9868C8.38092 13.0618 8.32676 13.1504 8.29213 13.2472C8.2575 13.3441 8.24315 13.447 8.24996 13.5495C8.26655 13.7427 8.35579 13.9224 8.49968 14.0524C8.64357 14.1824 8.8314 14.2529 9.02527 14.2499H11.25V21.3074C11.2503 21.3603 11.2394 21.4127 11.218 21.4611C11.1967 21.5095 11.1653 21.5528 11.126 21.5882C11.0866 21.6237 11.0403 21.6504 10.9899 21.6666C10.9395 21.6828 10.8863 21.6882 10.8337 21.6824C8.40498 21.3866 6.1758 20.1898 4.5874 18.3288C2.99901 16.4678 2.16716 14.0783 2.25652 11.6333C2.44402 6.57079 6.54464 2.45517 11.6109 2.25829C12.9225 2.20748 14.231 2.42175 15.4579 2.88826C16.6848 3.35477 17.8051 4.06395 18.7516 4.97338C19.6981 5.88281 20.4515 6.97381 20.9667 8.18111C21.4819 9.38842 21.7483 10.6872 21.75 11.9999Z"
          fill={!!props.fill ? props.fill : "white"}
        />
      </g>
      <defs>
        <clipPath id="clip0_175_493">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FaceBook;
