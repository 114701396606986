export const hindi = {
  SECTION_1_META_1:
    "भगवान श्री राम कहते हैं कि कहीं भी जाने के लिए सही दिशा या सही रास्ते का होना जरूरी है।",
  SECTION_2_META_2:
    "मार्ग केजीबी - आपको भगवान से जुड़ने और उनके मंदिरों और तीर्थ स्थानों के माध्यम से उन तक पहुंचने में मदद करने का एक प्रयास है।",
  OUR_SERVICE: "हमारी सेवाएँ",
  OUR_SERVICE_TAG: "एक मार्ग - कर्म ज्ञान भक्ति",
  OUR_SERVICE_DESCRIPTION:
    "हमें अपनी सेवा करने का मौका दें और खुद को भगवान श्री राम के चरणों में समर्पित करें।",
  TRANSPORT: "परिवहन/कमरे",
  TRANSPORT_TYPE: "ई-रिक्शा, ऑटो, कैब, बस, नाव, ठहरने की जगहें और क्रूज",
  DARSHAN: "दर्शन/पूजा",
  DARSHAN_TYPE: "मंदिर दर्शन, पूजा, आरती, प्रसाद और पंडित",
  FOOD: "पेटपूजा/भोजन",
  FOOD_TYPE: "रेस्तरां, मिष्ठान और स्टॉल",
  SHOPPING: "खरीददारी/शॉपिंग",
  SHOPPING_TYPE: "शोरूम, दुकान और स्टॉल",
  ABOUT: "क्यों अयोध्या",
  ABOUT_1:
    "ऐतिहासिक रूप से अयोध्या को साकेत के नाम से जाना जाता था जो सभ्य भारत में छठी शताब्दी के दौरान एक महत्वपूर्ण शहर था। दरअसल, बुद्ध के समय साकेत पर प्रसेनदी का शासन था जिसकी राजधानी श्रावस्ती थी। मौर्य शासन के दौरान भी साकेत ने अपनी प्रमुखता जारी रखी और 190 ईसा पूर्व के दौरान पंचाल और माथुर से संबद्ध बैक्ट्रियन यूनानी अभियान द्वारा उस पर हमला किया गया था।",
  ABOUT_2:
    "ब्रिटिश शासन से भारत की आजादी के बाद आध्यात्मिक दृष्टि से अयोध्या उत्तर प्रदेश का एक महत्वपूर्ण शहर रहा है।",
  ABOUT_3:
    "राम (/ˈrɑːmə/; संस्कृत: राम, रोमानीकृत: राम; संस्कृत: [ˈraːmɐ]) हिंदू धर्म में एक प्रमुख देवता हैं। वह विष्णु के सातवें और सबसे लोकप्रिय अवतारों में से एक हैं। हिंदू धर्म की राम-केंद्रित परंपराओं में, उन्हें सर्वोच्च व्यक्ति माना जाता है",
  PLACE_TO_VISIT: "घूमने के स्थान:",
  RAM_MANDIR: "राम मंदिर",
  SURAJ_KUND: "सूरज कुंड",
  CHHAPAIYA: "छपैया",
  SARYU_GHAT: "सरयू घाट",
  SARYU_AARTI: "सरयू आरती",
  LAKSHMAN_KILLA: "लक्ष्मण किला",
  HANUMANGHARI: "हनुमानगढ़ी",
  GUPTAR_GHAT: "गुप्तार घाट",
  MANI_PARVAT: "मणि पर्वत",
  DASHRATH_MAHAL: "दशरथ महल",
  KALENATH: "कालेनाथ",
  RAM_KI_PALDI: "राम की पलड़ी",
  RANG_MAHAL_MANDIR: "रंग महल मंदिर",
  SITA_KI_RASOI: "सीता की रसोई",
  RAJ_DWAR: "राज द्वार",
  TRETA_KE_THAKUR: "त्रेता के ठाकुर",
  KANAK_BHAWAN: "कनक भवन",
  NAGESHWARA_NATH_TEMPLE: "नागेश्वर नाथ मंदिर",
  MANIRAM_DAS_CHHAWANI: "मणिराम दास छावनी",
  SITARAJ_MAHAL_MANDIR: "सीताराज महल मंदिर",
  DEOKALI_KUND: "देवकाली कुंड",
  RAM_PATH: "राम पथ",
  LATA_MANGESHKAR_CHAUK: "लता मंगेशकर चौक",
  DOWNLOAD: "डाउनलोड",
  DOWNLOAD_APP: "हमारा ऐप डाउनलोड करें",
  DOWNLOAD_TAG: "एक आस्था जो जूडी हुई है धर्म से",
  CONTACT_US: "संपर्क करें/मार्गदर्शक",
  NAME: "आपका नाम",
  EMAIL: "आपका ईमेल",
  MOBILE: "आपका फोन नंबर",
  MESSAGE: "संदेश",
  SUBMIT: "सबमिट",
  HOME: "होम",
  MARG_KGB: "मार्ग - कर्म ज्ञान भक्ति",
  COMING_SOON: "जल्द आ रहा है",
  MAHAKUMBH: "महाकुंभ 2025 प्रयागराज",
};
