import Phone1 from "../../assets/images/phone1.png";
import Phone2 from "../../assets/images/phone2.png";
import PlayStore from "../../assets/images/GooglePlayBadge.png";
import AppStore from "../../assets/images/AppStoreBadge.png";

const Download = ({ t }) => {
  return (
    <div className="bg-varden min-[1281px]:px-40" id="Download">
      <div className="min-[1281px]:container mx-auto">
        <div className="flex mx-auto justify-center max-[1281px]:flex-col-reverse items-center">
          <div className="flex-1 my-auto max-[1281px]:bg-white w-full">
            <div className="max-w-[580px] m-auto max-[1281px]:flex max-[1281px]:flex-col justify-center items-center max-[1281px]:mt-10 max-[1281px]:px-10">
              <h1 className="text-dark-brown font-bold text-[42px] mb-3 w-fit max-md:text-3xl">
                {t("DOWNLOAD_APP")}
              </h1>
              <p className="font-bold text-xl text-dark-brown w-3/5 leading-7 mb-8 max-[1281px]:w-auto max-[1281px]:text-center tracking-wider">
                {t("DOWNLOAD_TAG")}
              </p>
              <p className="text-dark-brown mb-2 w-fit">Get the App</p>
              <div className="flex w-fit max-[1281px]:flex-col max-[1281px]:space-y-5">
                <a
                  href="https://play.google.com/store/apps/details?id=com.margkgb.app"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={PlayStore}
                    alt="Play Store"
                    className="mr-3 max-[1281px]:w-40"
                  />
                </a>
                <a
                  href="https://apps.apple.com/us/app/margkgb/id6476858183"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={AppStore}
                    alt="App Store"
                    className="max-[1281px]:w-40"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="flex flex-1 justify-center max-[1281px]:px-10">
            <img
              src={Phone1}
              alt="Phone Screen"
              className="max-[1281px]:w-52 h-fit"
            />
            <img
              src={Phone2}
              alt="Phone Screen"
              className="mt-12 max-[1281px]:w-36 h-fit"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Download;
