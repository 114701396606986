export const english = {
  SECTION_1_META_1:
    "Lord Shri Ram says that to go anywhere it is necessary to have the right direction or the right path.",
  SECTION_2_META_2:
    "MARG KGB - Is an effort to help you connect with God and reach Him through His temples and pilgrimage places.",
  OUR_SERVICE: "Our Services",
  OUR_SERVICE_TAG: "Ek Marg - Karm Gyan Bhakti",
  OUR_SERVICE_DESCRIPTION:
    "Give us a chance to serve you and place yourself at the feet of Lord Shri Ram.",
  TRANSPORT: "Transport/Rooms",
  TRANSPORT_TYPE: "E-rickshaw, Auto, Cab, Bus, Boat, Hotels & cruise",
  DARSHAN: "Darshan/Pooja",
  DARSHAN_TYPE: "Mandir Darshan, Pooja, Arti, Prashad and Pandit",
  FOOD: "Petpooja/Food",
  FOOD_TYPE: "Restaurant, Misthan and Stalls",
  SHOPPING: "Kharidari/Shopping",
  SHOPPING_TYPE: "Showroom, Shop and Stalls",
  ABOUT: "About Ayodhya",
  ABOUT_1:
    "Historically Ayodhya was known as Saketa which was an important city during the 6th Century in civilized India. Indeed, during Buddha’s time, Saketa was ruled by Prasenadi whose capital was Sravasti. Even during Maurya rule Saketa continued its prominence and was attacked by a Bactrian Greek expedition allied to Panchala and Mathur during 190 B.C.",
  ABOUT_2:
    "After independence of India from British rule, Ayodhya has been an important city of Uttar Pradesh from spiritual point of view.",
  ABOUT_3:
    "Rama (/ˈrɑːmə/; Sanskrit: राम, romanized: Rāma; Sanskrit: [ˈraːmɐ]) is a major deity in Hinduism. He is the seventh and one of the most popular avatars of Vishnu. In Rama-centric traditions of Hinduism, he is considered the Supreme Being",
  PLACE_TO_VISIT: "Places to visit:",
  RAM_MANDIR: "Ram Mandir",
  SURAJ_KUND: "Suraj Kund",
  CHHAPAIYA: "Chhapaiya",
  SARYU_GHAT: "Saryu Ghat",
  SARYU_AARTI: "Saryu Aarti",
  LAKSHMAN_KILLA: "Lakshman Killa",
  HANUMANGHARI: "Hanumanghari",
  GUPTAR_GHAT: "Guptar Ghat",
  MANI_PARVAT: "Mani Parvat",
  DASHRATH_MAHAL: "Dashrath Mahal",
  KALENATH: "Kalenath",
  RAM_KI_PALDI: "Ram KI paldi",
  RANG_MAHAL_MANDIR: "Rang Mahal Mandir",
  SITA_KI_RASOI: "Sita Ki Rasoi",
  RAJ_DWAR: "Raj Dwar",
  TRETA_KE_THAKUR: "Treta Ke Thakur",
  KANAK_BHAWAN: "Kanak Bhawan",
  NAGESHWARA_NATH_TEMPLE: "Nageshwara Nath Temple",
  MANIRAM_DAS_CHHAWANI: "Maniram Das Chhawani",
  SITARAJ_MAHAL_MANDIR: "Sitaraj Mahal Mandir",
  DEOKALI_KUND: "Deokali Kund",
  RAM_PATH: "Ram Path",
  LATA_MANGESHKAR_CHAUK: "Lata Mangeshkar chauk",
  DOWNLOAD: "Download",
  DOWNLOAD_APP: "Download Our App",
  DOWNLOAD_TAG: "Ek astha jo judi hui hai dharm se",
  CONTACT_US: "Contact Us/Marg Darshak",
  NAME: "Your name",
  EMAIL: "Your email",
  MOBILE: "Your phone number",
  MESSAGE: "Message",
  SUBMIT: "Submit",
  HOME: "Home",
  MARG_KGB: "MARG - KARM GYAN BHAKTI",
  COMING_SOON: "COMING SOON",
  MAHAKUMBH: "Mahakumbh 2025 Prayagraj",
};
