const ContactUs = ({ t }) => {
  return (
    <div
      className="bg-white container mx-auto p-20 max-[767px]:p-10"
      id="contact-us"
    >
      <div className="shadow-2xl px-10 py-20 rounded-xl flex items-center justify-center max-[1280px]:flex-col-reverse max-[520px]:px-5">
        <div className="max-[1280px]:text-center w-3/5 max-lg:w-auto">
          <h1 className="text-dark-brown font-bold text-4xl mb-4 max-[1280px]:text-center max-md:text-3xl max-[462px]:text-2xl">
            {t("CONTACT_US")}
          </h1>

          <div className="mb-8 w-4/5 max-[1280px]:mx-auto">
            <input type="text" placeholder={t("NAME")} />
            <div className="flex space-x-5 max-[767px]:flex-col max-[767px]:space-x-0">
              <input type="email" placeholder={t("EMAIL")} />
              <input type="text" placeholder={t("MOBILE")} />
            </div>
            <input type="text" placeholder={t("MESSAGE")} />
          </div>
          <button className="submitBtn px-10 py-3 text-white">
            {t("SUBMIT")}
          </button>
        </div>
        <div className="w-[40%] max-[1280px]:mb-10 max-[1280px]:w-auto">
          <iframe
            className="rounded-2xl w-[400px] h-[350px] max-[660px]:max-w-[250px] max-[660px]:h-[200px]"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28492.610157844058!2d82.17856828035006!3d26.78977371009723!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399a07937e6d2823%3A0x5fc8f683b17f222b!2sAyodhya%2C%20Uttar%20Pradesh!5e0!3m2!1sen!2sin!4v1708325348012!5m2!1sen!2sin"
            // style="border:0;"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
          {/* <img src={Map} alt="Map" className="h-full rounded-2xl" /> */}
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
