export const STATIC_DATA = {
  PRIVACY: `
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          ><strong>INTRODUCTION:</strong></span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >MARGKGB TECHNOLOGIES PVT. LTD. is acquainted with privacy of the
          users and considers the importance of keeping the information
          confidential. This Privacy Policy is an integral part of your User
          Agreement with MARGKGB TECHNOLOGIES PVT. LTD.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >This Privacy Policy is relevant for those who are the end users
          i.e. those who are willing to purchase, who are interested in the
          services offered by MARGKGB TECHNOLOGIES PVT. LTD.. or through any
          of the customer interface channels including its website, mobile
          site, mobile app &amp; offline channels including call centers and
          offices in any parts of India or abroad.
        </span></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >This Privacy Policy applies to the website, communication page and
          services including off-site Services, such as our email services,
          customer care, support services and contact us. By using or
          accessing the website or other Sales Channels, if the User hereby
          agrees with the terms of this Privacy Policy and the contents herein
          then only the user will be eligible to access the services we offer.
        </span></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >This Privacy Policy does not apply to any website(s), mobile sites
          and mobile apps of third parties, even if their websites/products
          are linked to our website.</span
        ></span
      ></span
    ><span style="color: #0d0d0d"
      ><span style=""><strong> </strong></span
    ></span>
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          ><strong>PERSONAL DATABASE OF THE USER</strong></span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >To work with the users, we have to collect personal and sensitive
          data. That should be stored in the secret database maintained by the
          company wherein no access shall be granted. The following are some
          of the information that MARGKGB TECHNOLOGIES PVT. LTD. Require for
          processing the services:</span
        ></span
      ></span
    >
  </p>
  
  <ul>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >Name, gender, age and date of birth</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >Traveling details and the itinerary</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >Contact information such as address, email, and mobile phone
              number</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large">Country of residence</span></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >Travel preferences on flights, room preferences in hotels
              (e.g., smoking/non-smoking, bed type), dietary restrictions, and
              any special accommodations needed.</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >Payment Details like Credit Card/ Debit Card details along with
              their expiry date (billing address in case of international
              debit/credit card).</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >Passport Number &amp; frequent flyer number (wherever
              needed)</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >Family details/information (for example, your marital status
              and dependents)</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >Employment and education details (for example, the organization
              you work for, your job title and your education details)</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >Financial and tax-related information (like your income,
              investments and tax residency)</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >Disclosure of any disease or disability.</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >IP address, browser type and language, your access times</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >Details of how you use our products and services</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >Users may be requested to provide emergency contact
              information.</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >CCTV footage and other information we collect when you access
              our premises</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >Recording of telephone calls to our representatives and call
              centers</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >Details of how you like to interact with us (through email, SMS
              or whatsapp) and other similar information relevant to our
              relationship.</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >Dietary requirements and any kind of health issues or specific
              preferences<br /></span></span
        ></span>
      </span>
    </li>
  </ul>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          ><strong>INTERNATIONAL BOOKINGS</strong></span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >Any data shared with MARGKGB TECHNOLOGIES PVT. LTD. shall have
          database in India. The point of transaction shall remain India at
          any case, subject to promotion of tour and travel beyond our
          territory where the third-party contract has been made for promoting
          such programmes. The international bookings will also be accepted by
          MARGKGB TECHNOLOGIES PVT. LTD. Without any prejudice to any matter.
        </span></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >In India the data protection, data handling and database creation
          depends upon various factors and differ from industry to industry
          and country to country. The user agrees to the policies of MARGKGB
          TECHNOLOGIES PVT. LTD. with the explicit consent to process the
          personal information for the purpose(s) defined in this policy to
          meet the user&rsquo;s requirement.
        </span></span
      ></span
    ><span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >The sharing and processing of information may extend to the hotel
          where you will stay or the tour manager who will be your guide
          during the travel. Such processing of data is essential for us to be
          able to provide service to you, then we may not be able to serve or
          confirm your bookings after your withdrawal of consent. The
          withdrawal or inability to travel shall not be a reason of refund.
          After due bookings there shall be no liability of the user
        </span></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >If you have any concerns in the processing your data and wish to
          withdraw your consent, you may do so by writing to the following
          email id: ___________.
        </span></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""><strong>NOTE: </strong></span></span
    ><span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >MARGKGB TECHNOLOGIES PVT. LTD.</span
        ></span
      ></span
    ><span style="color: #0d0d0d"
      ><span style=""
        ><strong>
          WILL NOT BE RESPONSIBLE FOR ANY ISSUES, INCLUDING INABILITY TO
          TRAVEL, ARISING OUT OF SUCH VISA REQUIREMENTS AND IS ALSO NOT LIABLE
          TO REFUND ANY AMOUNT TO THE USER FOR BEING UNABLE TO UTILIZE THE
          BOOKING DUE TO ABSENCE OR DENIAL OF VISA, IRRESPECTIVE WHETHER OR
          NOT THE USER HAS AVAILED THE SERVICES. WE SHALL NOT BE RESPONSIBLE
          FOR ANY THIRD-PARTY SERVICES. WE ARE ONLY CONNECTING THE USERS WITH
          THIRD-PARTY, WE SHALL NOT STAND LIABLE FOR ANY OF THE CONCERNED
          MMATTER.</strong
        ></span
      ></span
    >
  </p>
  <p align="justify"><br /></p>
  <p align="justify">
    <span style=""
      ><span style="font-size: large"
        ><strong
          ><span style="color: #0d0d0d"
            >THIRD PARTY CONTRACT AND LEGAL OBLIGATION:</span
          ></strong
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >The information as detailed below is collected for us to be able to
          provide the services chosen by you and also to fulfill our legal
          obligations as well as our obligations towards third parties as per
          our User Agreement.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style=""
      ><span style="font-size: large"
        ><span style="color: #0d0d0d">Personal Information </span></span
      ></span
    ><span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >of user shall include the information shared by the user.
          Information which you provide while subscribing to or registering on
          the Website, including but not limited to information about your
          personal identity such as name, gender, marital status, religion,
          age, profile picture etc., your contact details such as your email
          address, postal addresses, frequent flyer number, telephone (mobile
          or otherwise) and/or fax numbers. The information may also include
          information such as your banking details including billing
          information payment history etc.
        </span></span
      ></span
    >
  </p>
  
  <p align="justify">
    <span style=""
      ><span style="font-size: large"
        ><strong
          ><span style="color: #0d0d0d"
            >Supplementary Information:</span
          ></strong
        ></span
      ></span
    ><span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >&nbsp;There may be need of collecting other information, data and
          documents that may not be limited to following:</span
        ></span
      ></span
    >
  </p>
  
  <ul>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >Usernames, passwords, email addresses and other
              security-related information used by you in relation to our
              Services.</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >Data either created by you or by a third party and which you
              wish to store on our servers such as image files, documents
              etc.</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >Data available in public domain or received from any third
              party including social media channels, including but not limited
              to personal or non-personal information from your linked social
              media channels.</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >Information pertaining any other traveller(s) for who you make
              a booking through your registered account at this website or
              other channels. In such case, you must confirm and represent
              that each of the other traveller(s) for whom a booking has been
              made, has agreed to have the information shared by you disclosed
              to us and further be shared by us with the concerned service
              provider(s).</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >If you request to provide visa related services, then copies of
              your passport, bank statements, originals of the filled in
              application forms, photographs, and any other information which
              may be required by the respective embassy to process your visa
              application.</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >For international bookings, Users, in compliance with the
              Liberalized Remittance Scheme (LRS) of RBI or any other law may
              be required to provide details such as their PAN information or
              passport details number or any such information required by
              Service Provider. Such information shall be strictly used as per
              the aforesaid requirements only. In case a User does not wish to
              provide this information, MARGKGB TECHNOLOGIES PVT. LTD. may not
              be able to process the booking. MARGKGB TECHNOLOGIES PVT. LTD.
              will never share User&rsquo;s PAN details without their prior
              consent unless otherwise such action is required by any law
              enforcement authority for investigation, by court order or in
              reference to any legal process.</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >In case you opt for contactless check-in at hotels, then copies
              of your government identification like Aadhar, driving license,
              election card etc., Self-declaration and any other information
              like date of birth, destination/origin of travel and place of
              residence that may be required by the concerned Hotel to honour
              your hotel booking.</span
            ></span
          ></span
        >
      </span>
    </li>
  </ul>
  
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >Such information shall be strictly used for the aforesaid specified
          &amp; lawful purpose only. User further understands that MARGKGB
          TECHNOLOGIES PVT. LTD. may share this information with the end
          service provider or any other third party for provision and
          facilitation of the desired booking. MARGKGB TECHNOLOGIES PVT. LTD.
          will not be responsible for any third-party contract or bookings.
          Any booking through MARGKGB TECHNOLOGIES PVT. LTD. will be
          considered as independent and we shall not be liable for any
          consequences after bookings or during the stay or journey. We shall
          not be liable for any correspondence and communication made by us or
          on our behalf</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >We guarantee timely bookings and services on our portal but
          third-party involvement will not be considered in our zone. We will
          not be giving any insurance of any after services by third party
          commitments.</span
        ></span
      ></span
    >
  </p>
  
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          ><strong
            >HOW TO USE THE PERSONAL INFORMATION OF THE USER SO
            COLLECTED?</strong
          ></span
        ></span
      ></span
    >
  </p>
  <ol>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              ><strong>For booking purpose</strong></span
            ></span
          ></span
        >
      </span>
    </li>
  </ol>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >While booking, we may use Personal Information including, payment
          details which include cardholder name, credit/debit card number (in
          encrypted form) with expiration date, banking details, wallet
          details etc. as shared and allowed to be stored by you. We may also
          use the information of travellers list as available in or linked
          with your account. This information is presented to the User at the
          time of making a booking to enable you to complete your bookings
          expeditiously.</span
        ></span
      ></span
    >
  </p>
  <ol start="2">
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              ><strong>Other reasons</strong></span
            ></span
          ></span
        >
      </span>
    </li>
  </ol>
  <ul>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >Request for reviews of products or services or any other
              improvements;</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >Send verification message(s) or email(s);</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >Authenticate your account and to prevent any misuse or
              abuse.</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >Confirm your reservations with respective service
              providers;</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >Keep you informed of the transaction status;</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >Send booking confirmations either via sms or Whatsapp or any
              other messaging service;</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >Send any updates or changes to your booking(s);</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >Allow our customer service to contact you, if necessary;</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >Contact you on your birthday/anniversary to offer a special
              gift or offer</span
            ></span
          ></span
        ><span style="color: #0d0d0d"
          ><span style=""><span style="font-size: large">.</span></span></span
        >
      </span>
    </li>
  </ul>
  
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          ><strong>FEEDBACK AND REVIEWS:</strong></span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >The users feedback and comments play a vital role and that can only
          be possible with the help of surveys both online and offline.
          Participation in these surveys is entirely optional. The information
          received is aggregated, and used to make improvements to Website,
          other Sales Channels, services and to develop appealing content,
          features and promotions for members based on the results of the
          surveys. Identity of the survey participants is anonymous unless
          otherwise stated in the survey.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          ><strong>MARKETING TECHNIQUES AND RESEARCH PROGRAMS:</strong></span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >MARGKGB TECHNOLOGIES PVT. LTD. can employ various marketing
          techniques to attract and retain customers. Many strategies like
          sponsored promotions to give its Users the opportunity to win great
          travel and travel related prizes. By producing valuable and
          informative content such as blog posts, travel guides, destination
          reviews, and travel tips the user may get more access to our
          services. Further by sharing this content on our website, social
          media channels, and email newsletters potential customers will be
          attracted.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >As a registered User, you will also occasionally receive updates
          from us about fare sales in your area, special offers, new MARGKGB
          TECHNOLOGIES PVT. LTD. services, other noteworthy items and
          marketing programs. This era will leverage popular social media
          platforms such as Facebook, Instagram, Twitter, and Pinterest to
          showcase our tour packages, share user-generated content, run
          contests or giveaways, and engage with audience at large. Using
          visually appealing images and videos to inspire travel enthusiasts.
          Collaborations with travel influencers, bloggers, vloggers, and
          social media personalities to promote tour packages. Choose
          influencers whose audience aligns with our target demographic and
          offer them complimentary tours in exchange for promotional
          content.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >By Investing in paid advertising channels such as Google Ads,
          Facebook Ads, Instagram Ads, and YouTube Ads we can target potential
          customers based on their demographics, interests, and online
          behavior. We can encourage satisfied customers to refer their
          friends and family to our company by offering them incentives such
          as discounts, freebies, or loyalty rewards for each successful
          referral.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >MARGKGB TECHNOLOGIES PVT.LTD. will retain your Personal Information
          on its servers for as long as is reasonably necessary for the
          purposes listed in this policy. In some circumstances we may retain
          your Personal Information for longer periods of time, for instance
          where we are required to do so in accordance with any legal,
          regulatory, tax or accounting requirements.
        </span></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >Where your personal data is no longer required, we will ensure it
          is either securely deleted or stored in a way which means it will no
          longer be used by the business.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"><strong>COOKIES:</strong></span></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >In order to provide you with a more personalized and responsive
          service, we need to remember and store information about how you use
          this Website. This is done using small text files called cookies.
          This Website uses cookies to improve the user experience and ensure
          that it is functioning effectively, and on occasion also to provide
          marketing information or advertise online, either directly or
          through third party vendors.</span
        ></span
      ></span
    >
  </p>
  
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >By using this Website, you agree to MARGKGB TECHNOLOGIES PVT. LTD.
          use of cookies as described in this Cookie Notice.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          ><strong>How to control cookies?</strong></span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >By using this Website, you agree that MARGKGB TECHNOLOGIES PVT.
          LTD. can place cookies on your computer or device as explained
          above. Please bear in mind that removing or blocking cookies can
          impact user experience and parts of this website may no longer be
          fully accessible.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >MARGKGB TECHNOLOGIES PVT. LTD. uses cookies to personalize your
          experience on the Website and the advertisements that maybe
          displayed. MARGKGB TECHNOLOGIES PVT. LTD.&rsquo;s use of cookies is
          similar to that of any other reputable online companies.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >A cookie may also be placed by our advertising servers or
          third-party advertising companies. Such cookies are used for
          purposes of tracking the effectiveness of advertising served by us
          on any website, and also to use aggregated statistics about your
          visits to the Website in order to provide advertisements in the
          Website or any other website about services that may be of potential
          interest to you. The third-party advertising companies or
          advertisement providers may also employ technology that is used to
          measure the effectiveness of the advertisements.
        </span></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >Most web browsers automatically accept cookies. Of course, by
          changing the options on your web browser or using certain software
          programs, you can control how and whether cookies will be accepted
          by your browser. MARGKGB TECHNOLOGIES PVT. LTD.. supports your right
          to block any unwanted Internet activity, especially that of
          unscrupulous websites. However, blocking MARGKGB TECHNOLOGIES PVT.
          LTD.. cookies may disable certain features on the Website, and may
          hinder an otherwise seamless experience to purchase or use certain
          services available on the Website. Please note that it is possible
          to block cookie activity from certain websites while permitting
          cookies from websites you trust.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          ><strong>DATA SECURITY</strong></span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >We have taken extensive technical and operational security
          precautions to protect your data from being accidentally or
          intentionally manipulated, lost, destroyed, or accessed by
          unauthorized persons. Our security measures are reviewed regularly
          and updated in keeping with technological advances.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          ><strong>DATA STORAGE</strong></span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >We retain your personal data for as long as it is required for you
          to use our Services, to provide our Services to you, to comply with
          laws. We will anonymise and/or aggregate your data if we intend to
          use it for analytical statistical purposes over longer
          periods.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          ><strong>DATA RETENTION</strong></span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >We will retain the personal information we collect from you where
          we have a justifiable business need to do so and/or for as long as
          is needed to fulfil the purposes outlined in this Privacy Policy,
          unless a longer retention period is required or permitted by law
          (such as tax, legal, accounting, or other purposes).</span
        ></span
      ></span
    >
  </p>
  
  
  
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          ><strong>AUTOMATIC LOGGING OF SESSION:</strong></span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >Each time you access the Website your session data gets logged.
          Session data may consist of various aspects like the IP address,
          operating system and type of browser software being used and the
          activities conducted by the User while on the Website. We collect
          session data because it helps us analyse User&rsquo;s choices,
          browsing pattern including the frequency of visits and duration for
          which a User is logged on.
        </span></span
      ></span
    >
  </p>
  
  <p align="justify">
    <span style=""
      ><span style="font-size: large"
        ><strong
          ><span style="color: #0d0d0d"
            >YOUR PERSONAL DATA MAY BE SHARED WITH THE FOLLOWING:</span
          ></strong
        ></span
      ></span
    >
  </p>
  
  <ol>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              ><strong>Service Providers and suppliers:</strong></span
            ></span
          ></span
        >
      </span>
    </li>
  </ol>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >Your information shall be shared with the end service providers
          like airlines, hotels, bus service providers, cab rental, railways
          or any other suppliers who are responsible for fulfilling your
          booking. You may note that while making a booking with MARGKGB
          TECHNOLOGIES PVT. LTD., you authorize us to share your information
          with the said service providers and suppliers. It is pertinent to
          note that MARGKGB TECHNOLOGIES PVT. LTD.. does not authorize the end
          service provider to use your information for any other purpose(s)
          except as may be for fulfilling their part of service. However, how
          the said service providers/suppliers use the information shared with
          them is beyond the purview and control of MARGKGB TECHNOLOGIES PVT.
          LTD.. as they process Personal Information as independent data
          controllers, and hence we cannot be made accountable for the same.
          You are therefore advised to review the privacy policies of the
          respective service provider or supplier whose services you choose to
          avail.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >MARGKGB TECHNOLOGIES PVT. LTD.. does not sell or rent individual
          customer names or other Personal Information of Users to third
          parties except sharing of such information with our business /
          alliance partners or vendors who are engaged by us for providing
          various referral services and for sharing promotional and other
          benefits to our customers from time-to-time basis their booking
          history with us.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          ><strong>Companies in the same group:</strong></span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >In the interests of improving personalization and service
          efficiency, we may, under controlled and secure circumstances, share
          your Personal Information with our affiliate or associate entities.
          This will enable us to provide you with information about various
          products and services, both leisure- and travel-related, which might
          interest you; or help us address your questions and requests in
          relation to your bookings.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >If the assets of MARGKGB TECHNOLOGIES PVT. LTD. are acquired, our
          customer information may also be transferred to the acquirer
          depending upon the nature of such acquisition. In addition, as part
          of business expansion/development/restructuring or for any other
          reason whatsoever, if we decide to sell/transfer/assign our
          business, any part thereof, any of our subsidiaries or any business
          units, then as part of such restructuring exercise customer
          information including the Personal Information collected herein
          shall be transferred accordingly.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          ><strong>Business partners and third party vendors: </strong></span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >We may also share certain filtered Personal Information to our
          corporate affiliates or business partners who may contact the
          customers to offer certain products or services, which may include
          free or paid products / services, which will enable the customer to
          have better travel experience or to avail certain benefits specially
          made for MARGKGB TECHNOLOGIES PVT. LTD.. customers. Examples of such
          partners are entities offering co-branded credit cards, travel
          insurance, insurance cover against loss of wallet, banking cards or
          similar sensitive information etc. If you choose to avail any such
          services offered by our business partners, the services so availed
          will be governed by the privacy policy of the respective service
          provider.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >MARGKGB TECHNOLOGIES PVT. LTD.. may share your Personal Information
          to third party that MARGKGB TECHNOLOGIES PVT. LTD.. may engage to
          perform certain tasks on its behalf, including but not limited to
          payment processing, data hosting, and data processing
          platforms.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >We use non-identifiable Personal Information of Users in aggregate
          or anonymized form to build higher quality, more useful online
          services by performing statistical analysis of the collective
          characteristics and behaviour of our customers and visitors, and by
          measuring demographics and interests regarding specific areas of the
          Website. We may provide anonymous statistical information based on
          this data to suppliers, advertisers, affiliates and other current
          and potential business partners. We may also use such aggregate data
          to inform these third parties as to the number of people who have
          seen and clicked on links to their websites. Any Personal
          Information which we collect and which we may use in an aggregated
          format is our property. We may use it, in our sole discretion and
          without any compensation to you, for any legitimate purpose
          including without limitation the commercial sale thereof to third
          parties.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >Occasionally, MARGKGB TECHNOLOGIES PVT. LTD. will hire a third
          party for market research, surveys etc. and will provide information
          to these third parties specifically for use in connection with these
          projects. The information (including aggregate cookie and tracking
          information) we provide to such third parties, alliance partners, or
          vendors are protected by confidentiality agreements and such
          information is to be used solely for completing the specific
          project, and in compliance with the applicable regulations.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          ><strong>DISCLOSURE OF INFORMATION</strong></span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >In addition to the circumstances described above, MARGKGB
          TECHNOLOGIES PVT. LTD. may disclose User's Personal Information if
          required to do so:</span
        ></span
      ></span
    >
  </p>
  <ul>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >by law, required by any enforcement authority for
              investigation, by court order or in reference to any legal
              process;</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >to conduct our business;</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >for regulatory, internal compliance and audit exercise(s)</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >to secure our systems; or</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >to enforce or protect our rights or properties of MARGKGB
              TECHNOLOGIES PVT. LTD.. or any or all of its affiliates,
              associates, employees, directors or officers or when we have
              reason to believe that disclosing Personal Information of
              User(s) is necessary to identify, contact or bring legal action
              against someone who may be causing interference with our rights
              or properties, whether intentionally or otherwise, or when
              anyone else could be harmed by such activities.</span
            ></span
          ></span
        >
      </span>
    </li>
  </ul>
  
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          ><strong
            >NOTE-Such disclosure and storage may take place without your
            knowledge. In that case, we shall not be liable to you or any
            third party for any damages howsoever arising from such disclosure
            and storage.</strong
          ></span
        ></span
      ></span
    >
  </p>
  
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          ><strong>USER GENERATED CONTENT</strong></span
        ></span
      ></span
    >
  </p>
  
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >MARGKGB TECHNOLOGIES PVT. LTD. provides an option to its users to
          post their experiences by way of reviews, ratings and general poll
          questions. The customers also have an option of posting questions
          w.r.t a service offered by MARGKGB TECHNOLOGIES PVT. LTD.. or post
          answers to questions raised by other users. MARGKGB TECHNOLOGIES
          PVT. LTD.. may also hire a third party to contact you and gather
          feedback about your recent booking with MARGKGB TECHNOLOGIES PVT.
          LTD.. Though the participation in the feedback process is purely
          optional, you may still receive emails, notifications (app, sms,
          Whatsapp or any other messaging service) for you to share your
          review(s), answer questions posted by other users or a. The reviews
          may be written or in a video format. The reviews written or posted
          may also be visible on other travel or travel related
          platforms.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >Each User who posts review or ratings, Q&amp;A, photographs shall
          have a profile, which other Users will be able to access. Other
          Users may be able to view the number of trips, reviews written,
          questions asked and answered and photographs posted.</span
        ></span
      ></span
    >
  </p>
  
  <p align="justify">
    <span style=""
      ><span style="font-size: medium"
        ><strong
          ><span style="color: #0d0d0d"
            >OPT-OUT OF RECEIVING OUR PROMOTIONAL E-MAILS</span
          ></strong
        ></span
      ></span
    >
  </p>
  
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >You will occasionally receive e-mail updates from us about fare
          sales in your area, special offers, new MARGKGB TECHNOLOGIES PVT.
          LTD. services, and other noteworthy items. We hope you will find
          these updates interesting and informative. If you wish not to
          receive them, please click on the "unsubscribe" link or follow the
          instructions in each e-mail message.</span
        ></span
      ></span
    >
  </p>
  
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          ><u>MARGKGB TECHNOLOGIES PVT. LTD.</u></span
        ></span
      ></span
    ><span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          ><u><strong> &rdquo; APP (ANDROID AND IOS)</strong></u></span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >MARGKGB TECHNOLOGIES PVT. LTD.</span
        ></span
      ></span
    ><span style="color: #0d0d0d"
      ><span style=""
        ><strong>
          WILL NOT BE RESPONSIBLE FOR ANY ISSUES, INCLUDING INABILITY TO
          TRAVEL, ARISING OUT OF SUCH VISA REQUIREMENTS AND IS ALSO NOT LIABLE
          TO REFUND ANY AMOUNT TO THE USER FOR BEING UNABLE TO UTILIZE THE
          BOOKING DUE TO ANY SUSTANTIAL REASONS. WE ARE NOT LIABLE FOR ANY
          COMPENSATIONS, COMMITMENTS OR ASSURANCE MADE WITH ANY THIRD PARTY
          THROUGH OUR PORTAL.</strong
        ></span
      ></span
    >
  </p>
  
  <p align="justify">
    <span style=""
      ><span style="font-size: large"
        ><strong
          ><span style="color: #0d0d0d"
            >Permissions required for using our mobile applications</span
          ></strong
        ></span
      ></span
    >
  </p>
  
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >When the MARGKGB TECHNOLOGIES PVT. LTD. app is installed on your
          phone or tablet, a list of permissions appear and are needed for the
          app to function effectively. There is no option to customize the
          list. The permissions that MARGKGB TECHNOLOGIES PVT. LTD. requires
          and the data that shall be accessed and its use is as below:</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          ><u><strong>Android permissions:</strong></u></span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style=""
      ><span style="font-size: large"
        ><strong
          ><span style="color: #0d0d0d"
            >Device &amp; App history:</span
          ></strong
        ></span
      ></span
    ><span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >&nbsp;We need your device permission to get information about your
          device, like OS (operating system) name, OS version, mobile network,
          hardware model, unique device identifier, preferred language, etc.
          Basis these inputs, we intend to optimize your travel booking
          experience, use OS specific capabilities to drive great in-funnel
          experiences using components of device&rsquo;s OS, etc.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style=""
      ><span style="font-size: large"
        ><strong><span style="color: #0d0d0d">Camera:</span></strong></span
      ></span
    ><span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >&nbsp;This permission enables you to use your camera from within
          the application to upload a new profile picture, upload an image for
          sharing hotel reviews and also allows you to take videos to upload
          as video reviews on our application. This permission will also
          enable you to scan QR codes for easy UPI payments.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style=""
      ><span style="font-size: large"
        ><strong><span style="color: #0d0d0d">Identity:</span></strong></span
      ></span
    ><span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >&nbsp;This permission enables us to know about details of your
          account(s) on your mobile device. We use this info to auto-fill your
          email ID&rsquo;s and provide a typing free in-funnel experience. It
          helps us map email ID&rsquo;s to a particular user to give you the
          benefit of exclusive travel offers, wallet cash-backs, etc. It also
          allows facilitating your Facebook and Google+ login.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style=""
      ><span style="font-size: large"
        ><strong><span style="color: #0d0d0d">Location:</span></strong></span
      ></span
    ><span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >&nbsp;This permission enables us to give you the benefit of
          location specific deals and provide you a personalized in-funnel
          experience. When you launch MakeMyTrip app to make a travel booking,
          we auto-detect your location so that your nearest airport or city is
          auto-filled. We also require this permission to recommend you
          nearest hotels in case you are running late and want to make a quick
          last-minute booking for the nearest hotel. Your options are
          personalized basis your locations. For international travel, this
          enables us to determine your time zone and provide information
          accordingly</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style=""
      ><span style="font-size: large"
        ><strong><span style="color: #0d0d0d">SMS:</span></strong></span
      ></span
    ><span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >&nbsp;If you allow us to access your SMS, we read your SMS to
          autofill or prepopulate &lsquo;OTP&rsquo; while making a transaction
          and to validate your mobile number. This provides you a seamless
          purchase experience while making a booking and you don&rsquo;t need
          to move out of the app to read the SMS and then enter it in the
          app.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style=""
      ><span style="font-size: large"
        ><strong><span style="color: #0d0d0d">Phone:</span></strong></span
      ></span
    ><span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >&nbsp;The app requires access to make phone calls so that you can
          make phone calls to hotels, airlines and our customer contact
          centers directly through the app.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style=""
      ><span style="font-size: large"
        ><strong><span style="color: #0d0d0d">Contacts:</span></strong></span
      ></span
    ><span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >&nbsp;If you allow us to access your contacts, it enables us to
          provide a lot of social features to you such as sharing your hotel/
          flight/ holidays with your friends, inviting your friends to try our
          app, send across referral links to your friends, etc. We may also
          use this information to make recommendations for hotels where your
          friends have stayed. This information will be stored on our servers
          and synced from your phone.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style=""
      ><span style="font-size: large"
        ><strong
          ><span style="color: #0d0d0d">Photo/ Media/ Files:</span></strong
        ></span
      ></span
    ><span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >&nbsp;The libraries in the app use these permissions to allow map
          data to be saved to your phone's external storage, like SD cards. By
          saving map data locally, your phone doesn't need to re-download the
          same map data every time you use the app. This provides you a
          seamless Map based Hotel selection experience, even on low bandwidth
          network.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style=""
      ><span style="font-size: large"
        ><strong
          ><span style="color: #0d0d0d"
            >Wi-Fi connection information:</span
          ></strong
        ></span
      ></span
    ><span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >&nbsp;When you allow us the permission to detect your Wi-Fi
          connection, we optimize your experience such as more detailing on
          maps, better image loading, more hotel/ flights/ package options to
          choose from, etc.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style=""
      ><span style="font-size: large"
        ><strong
          ><span style="color: #0d0d0d"
            >Device ID &amp; Call information:</span
          ></strong
        ></span
      ></span
    ><span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >&nbsp;This permission is used to detect your Android ID through
          which we can uniquely identify users. It also lets us know your
          contact details using which we pre-populate specific fields to
          ensure a seamless booking experience.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style=""
      ><span style="font-size: large"
        ><strong><span style="color: #0d0d0d">Calendar:</span></strong></span
      ></span
    ><span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >&nbsp;This permission enables us to put your travel plan on your
          calendar.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style=""
      ><span style="font-size: large"
        ><strong><span style="color: #0d0d0d">Bluetooth:</span></strong></span
      ></span
    ><span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >&nbsp;This permission enables us to provide you services of the
          third parties that make available certain features of their
          products/services on our application. The permission specifically
          enables our third party service providers whose services you book on
          the application to permit you to lock and unlock your self-driven
          vehicles seamlessly.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style=""
      ><span style="font-size: large"
        ><strong
          ><span style="color: #0d0d0d">Video/Audio:</span></strong
        ></span
      ></span
    ><span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >&nbsp;This permission enables you to upload videos on our
          application or to submit video reviews of your hotel/property stays
          and thereby providing you with new ways of providing reviews of your
          experience on our application. The audio permission enables us to
          ensure that the video content that you upload has a clear audio in
          its background.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style=""
      ><span style="font-size: large"
        ><strong
          ><span style="color: #0d0d0d">Phone Number:</span></strong
        ></span
      ></span
    ><span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >&nbsp;We need the permission of access to phone number to provide a
          seamless login experience, to optimise your travel booking
          experience and to enable you to obtain UPI services and to prevent
          fraud in some cases on our application</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style=""
      ><span style="font-size: large"
        ><strong><span style="color: #0d0d0d">IMEI/IMSI:</span></strong></span
      ></span
    ><span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >&nbsp;This permission enables us to identifying different users
          uniquely and helps us to prevent frauds on our application.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style=""
      ><span style="font-size: large"
        ><strong
          ><span style="color: #0d0d0d"
            >Subscription Information:</span
          ></strong
        ></span
      ></span
    ><span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >&nbsp;Your subscription information enables to provide you a
          seamless experience depending upon your network and to optimise the
          application&rsquo;s performance. In addition this also enables us in
          providing to you the train status even in an offline mode and
          additionally even in a no network area you can get fully aware of
          the status of the train you want to search for.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style=""
      ><span style="font-size: large"
        ><strong
          ><span style="color: #0d0d0d">SIM Serial Number:</span></strong
        ></span
      ></span
    ><span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >&nbsp;This permission enables us to read your sim serial number and
          helps us in authenticating your mobile number for UPI registration.
          It helps us to ensure that the mobile number you are using is
          actually present on your device that you are using to register for
          UPI.</span
        ></span
      ></span
    >
  </p>
  
  <p align="justify">
    <span style="color: #262626"
      ><span style=""
        ><span style="font-size: large"
          ><u><strong>IOS Permissions:</strong></u></span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style=""
      ><span style="font-size: large"
        ><strong><span style="color: #0d0d0d">Contacts: </span></strong></span
      ></span
    ><span style=""
      ><span style="font-size: large"
        ><span style="color: #0d0d0d"
          >by enabling this, we would be able to provide social features such
          as sharing
        </span></span
      ></span
    ><span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >your hotel/ flight/ holidays with your friends, inviting your
          friends to try our app, send across referral links to your friends,
          etc. Even recommendations will be made for further stays.This
          information will be stored on our servers and synced from your
          phone.</span
        ></span
      ></span
    >
  </p>
  
  <p align="justify">
    <span style=""
      ><span style="font-size: large"
        ><strong
          ><span style="color: #0d0d0d">Notifications:</span></strong
        ></span
      ></span
    ><span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >&nbsp;It is easy for us to send exclusive deals, promotional
          offers, travel related updates, etc. on your device.
        </span></span
      ></span
    >
  </p>
  
  <p align="justify">
    <span style=""
      ><span style="font-size: large"
        ><strong><span style="color: #0d0d0d">Location:</span></strong></span
      ></span
    ><span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >&nbsp;When you launch our app to make a travel booking, we
          auto-detect your location so that your nearest Airport or City is
          auto-filled. We require this permission to recommend your nearest
          hotels in case you are running late and want to make a quick
          last-minute booking for the nearest hotel. Your options are
          personalized basis your locations. For international travel, this
          enables us to determine your time zone and provide information
          accordingly.</span
        ></span
      ></span
    >
  </p>
  
  
  <p align="justify">
    <span style="color: #262626"
      ><span style=""
        ><span style="font-size: large"
          ><strong>HOW WE PROTECT YOUR PERSONAL INFORMATION?</strong></span
        ></span
      ></span
    >
  </p>
  
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >We have appropriate technical and organizational measures to
          safeguard and help prevent unauthorized access to your information
          and to maintain data security. These safeguards take into account
          the sensitivity of the information that we collect, process and
          store and the current state of technology. We follow generally
          accepted industry standards to protect the personal information
          submitted to us, both during transmission and once we receive it.
          The third party with respect to payment gateway and payment
          processing are all validated as compliant with the payment card
          industry standard (generally referred to as PCI compliant service
          providers). You should not share your user name, password, or other
          security information of your account with anyone. If we receive
          instructions using your registered email ID or username, we will
          consider that you have authorized the instructions.</span
        ></span
      ></span
    >
  </p>
  
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          ><strong
            >We assume no liability or responsibility for disclosure of your
            information due to errors in transmission, unauthorized
            third-party access or other causes beyond our control.</strong
          ></span
        ></span
      ></span
    ><span style="color: #0d0d0d"
      ><span style=""><span style="font-size: large"> </span></span
    ></span>
  </p>
  
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >You may withdraw your consent to submit any or all Personal
          Information or decline to provide any permission on its Website as
          covered above at any time. In case, you choose to do so then your
          access to the Website may be limited, or we might not be able to
          provide the services to you. You may withdraw your consent by
          sending an email to&nbsp;___INFO@MARGKGB.COM__________________
        </span></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >You may access your Personal Information from your user account
          with MARGKGB TECHNOLOGIES PVT. LTD. You may also correct your
          personal information or delete such information (except some
          mandatory fields) from your user account directly. If you
          don&rsquo;t have such a user account, then you write to&nbsp;</span
        ></span
      ></span
    ><span style=""
      ><span style="font-size: large"
        ><a href="mailto:__INFO@MARGKGB.COM____________________."
          >__INFO@MARGKGB.COM____________________.</a
        ></span
      ></span
    >
  </p>
  
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          ><u><strong>Privacy Policy (EU, UK and US)</strong></u></span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          ><strong>Introduction:</strong></span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >MARGKGB TECHNOLOGIES PVT. LTD. is committed to our client&rsquo;s
          privacy and security. We believe your private details should be kept
          just that and when you share personal information with us, we will
          make all reasonable endeavours to keep your details secure and
          private. Please also read our Cookie Statement, which tells you how
          MARGKGB TECHNOLOGIES PVT. LTD. uses cookies and other similar
          tracking technologies. It also tells you what rights you can
          exercise in relation to your personal data and how you can contact
          us.
        </span></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >If you need to contact our customer service team, contact your
          booking agency through us, or reach out to us in a different way
          (such as social media or via a chatbot) we&rsquo;ll collect
          information from you there, too. This applies whether you are
          contacting us with feedback or asking for help using our services.
          We would not be responsible for any kind of payment failures,
          refunds if inability to travel or any other third-party contract.
          You might also be invited to write reviews to help inform others
          about the experiences you had on your trip/stay. When you write a
          review on the MARGKGB TECHNOLOGIES PVT. LTD. platform, we&rsquo;ll
          collect any information you&rsquo;ve included, along with your
          display name.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >If you create a user account, we&rsquo;ll also store your personal
          settings, uploaded photos, and reviews of previous bookings. This
          saved data can be used to help you plan and manage future
          reservations or benefit from other features only available to
          account holders (such as incentives or other benefits).</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          ><strong>DATA PROTECTION RIGHTS OF USERS</strong></span
        ></span
      ></span
    >
  </p>
  <ol>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >You may ask us to verify whether we are processing personal
              data about you, and if so, to provide more specific
              information.</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >You may ask us to correct our records if you believe they
              contain incorrect or incomplete information about you.</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >You may ask us to erase (delete) your personal data after you
              withdraw your consent to process it, or when we no longer need
              it for the purpose it was originally collected and we shall
              erase your personal data, subject to the data retention
              requirements under the applicable rules, regulations,
              guidelines, law, by-law, to meet our legal/regulatory
              obligations. Please note, even after completion of your request
              regarding account or data deletion, we will be retaining
              transactional data to meet our legal obligations.</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >You may ask us to temporarily restrict our processing of your
              personal data if you contest the accuracy of your personal data,
              prefer to restrict its use rather than having us erase it, or
              need us to preserve it for you to establish, exercise, or defend
              a legal claim. A temporary restriction may apply while verifying
              whether we have overriding legitimate grounds to process
              it.</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >You may ask us to review any decisions made about you which we
              made solely based on automated processing, including profiling,
              that produced legal effects concerning you or significantly
              affecting you.</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >Right to Withdraw Consent - You may withdraw your consent that
              you have previously given to one or more specified purposes to
              process your personal data. This will not affect the lawfulness
              of any processing carried out before you withdraw your consent.
            </span></span
          ></span
        >
      </span>
    </li>
  </ol>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >We may need to request specific information from you to help us
          confirm your identity and ensure your right to access the
          information or to exercise any of your other rights.
        </span></span
      ></span
    >
  </p>
  
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          ><strong>PERSONAL DATA ABOUT OTHERS</strong></span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >Of course, you might not simply be making a reservation for
          yourself. You might be taking a trip/stay with other people or
          making a reservation on someone else&rsquo;s behalf. In both those
          scenarios, you will provide their details as part of the
          reservation.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >If you have a MARGKGB TECHNOLOGIES PVT. LTD. for Business account,
          you can keep an address book there to make it easier to plan and
          manage business travel/stay arrangements for others.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >In some cases, you might use MARGKGB TECHNOLOGIES PVT. LTD. to
          share information with others. This can take the form of sharing a
          Wishlist, taking part in a travel community or participating in a
          referral program, as described when you use the relevant
          feature.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >At this point, we have to make it clear that it&rsquo;s your
          responsibility to ensure that the person or people you have provided
          personal data about are aware that you&rsquo;ve done so, and that
          they have understood and accepted how MARGKGB TECHNOLOGIES PVT. LTD.
          uses their information (as described in this Privacy Policy).</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >Whether or not you end up making a reservation, when you visit our
          websites or apps, we automatically collect certain information. This
          includes your IP address, the date and time you accessed our
          services, and information about your computer&rsquo;s hardware and
          software (such as the operating system, the internet browser used,
          software/application version data and your language settings). We
          also collect information about clicks and which pages have been
          shown to you.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >If you&rsquo;re using a mobile device, we collect data that
          identifies the device, as well as data about your device-specific
          settings and characteristics, app crashes and other system activity.
          When you make a booking arrangement using this kind of device, our
          system registers how you made your reservation (on which website),
          and/or which site you came from when you entered the MARGKGB
          TECHNOLOGIES PVT. LTD. Website or app.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          ><strong>DATA WE RECEIVE FROM OTHER SOURCES.</strong></span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >Anything we receive from these partners may be combined with
          information provided by you. For example, MARGKGB TECHNOLOGIES PVT.
          LTD.. Reservation services are not only made available via MARGKGB
          TECHNOLOGIES PVT. LTD. and the MARGKGB TECHNOLOGIES PVT. LTD.. apps
          but are also integrated into services of affiliate partners you can
          find online. When you use any of these services, you provide the
          reservation details to our business partners who then forward your
          details to us.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >We also integrate with third party service providers to facilitate
          payments between you and booking agencies. These service providers
          share payment information so we can administer and manage your
          Booking arrangement, making sure everything goes as smoothly as
          possible for you.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >Additionally, we collect information in the regrettable case that
          we receive a complaint about you from a Booking agency, for example
          in the case of misconduct.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >Another way we might receive data about you, is through the
          communication services integrated into our platforms. These
          communication services offer you a way to contact the booking agency
          you&rsquo;ve booked with to discuss your stay. In some cases, we
          receive metadata about these communication activities (such as who
          you are, where you called from, and the date and length of the
          call).</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >We use the information collected about you for a variety of
          purposes. Your personal data may be used for booking agreement,
          customer services, account facilities. That may include various
          ancillary services, such as reservation information or information
          about your user account,</span
        ></span
      ></span
    >
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >communications that relate to your booking arrangement, such as
          confirmations, modifications, and reminders.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >Managing personal settings gives you the ability to keep and share
          lists, share photos, easily see trip/stay Services you&rsquo;ve
          searched for and check travel-related information you&rsquo;ve
          provided. You can also see any reviews you&rsquo;ve written.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >If you want to, you can share certain information as part of your
          user account, by creating a public profile under your own first name
          or a screen name you choose.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >If you&rsquo;re a MARGKGB TECHNOLOGIES PVT. LTD. for Business
          account holder, you can also save contact details under that
          account, manage business reservations and link other account holders
          to the same MARGKGB TECHNOLOGIES PVT. LTD. for Business
          account.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >We give account holders the chance to connect and interact with
          each other through online groups or forums, such as travel
          communities. We use your information for marketing activities. These
          activities include individualised offers, in mobile apps or on
          third-party websites/apps, promotional activities. There might be
          other times when we get in touch, including by email, by chatbot, by
          post, by phone or by texting you. Which method we choose depends on
          the contact information you&rsquo;ve previously shared. MARGKGB
          TECHNOLOGIES PVT. LTD.. also offers customers and booking agencies
          several ways to exchange information, requests, and comments about
          Booking agencies and existing booking arrangements via MARGKGB
          TECHNOLOGIES PVT. LTD.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          ><strong>Data sharing with third parties</strong></span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >In certain circumstances, we&rsquo;ll share your personal data with
          third parties. These third parties include:</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >The booking agency you booked: To complete your booking
          arrangement, we transfer relevant reservation details to the booking
          agency you have booked. This is one of the most essential things we
          do for you.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >Depending on the booking arrangement and the Booking agency, the
          details we share can include your name, contact and payment details,
          the names of the people accompanying you and any other information
          or preferences you specified when you made your booking
          arrangement.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >In certain cases, we also provide some additional historical
          information about you to the Booking agency. This includes whether
          you&rsquo;ve already booked with them in the past, the number of
          completed bookings you&rsquo;ve made with MARGKGB TECHNOLOGIES PVT.
          LTD.., a confirmation that no misconduct has been reported about
          you, the percentage of bookings you&rsquo;ve cancelled in the past
          or whether you&rsquo;ve given reviews about past bookings.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >If you have a query about your trip/stay, we may contact the
          booking agency to handle your request. Unless payment is made during
          the booking process, via the MARGKGB TECHNOLOGIES PVT. LTD. website,
          we will forward your credit card details to the booking agency for
          further handling (assuming you&rsquo;ve provided us with those
          details).</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >In cases of Booking arrangement-related claims or disputes, we may
          provide the booking agency with your contact details and other
          information about the booking process, as needed to resolve the
          situation. This can include, but might not be limited to, your email
          address and a copy of your reservation confirmation as proof that a
          booking arrangement was made or to confirm reasons for
          cancellation.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >For completeness, booking agencies will further process your
          personal data outside of the control of MARGKGB TECHNOLOGIES PVT.
          LTD.. Booking agencies may also ask for additional personal data,
          for instance to provide additional services, or to comply with local
          restrictions. If available, please read the Privacy Statement of the
          booking agency to understand how they process your personal
          data.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >We use service providers from outside of the MARGKGB TECHNOLOGIES
          PVT. LTD.. corporate group to support us in providing our services.
          These include:</span
        ></span
      ></span
    >
  </p>
  <ul>
    <ul>
      <li style="margin-bottom:10px;">
        <p align="justify">
          <span style="color: #0d0d0d"
            ><span style=""
              ><span style="font-size: large">Customer support</span></span
            ></span
          >
        </p>
      </li>
      <li style="margin-bottom:10px;">
        <p align="justify">
          <span style="color: #0d0d0d"
            ><span style=""
              ><span style="font-size: large">Market research</span></span
            ></span
          >
        </p>
      </li>
      <li style="margin-bottom:10px;">
        <p align="justify">
          <span style="color: #0d0d0d"
            ><span style=""
              ><span style="font-size: large"
                >Fraud detection and prevention (including anti-fraud
                screening)</span
              ></span
            ></span
          >
        </p>
      </li>
      <li style="margin-bottom:10px;">
        <p align="justify">
          <span style="color: #0d0d0d"
            ><span style=""
              ><span style="font-size: large">Insurance claims</span></span
            ></span
          >
        </p>
      </li>
      <li style="margin-bottom:10px;">
        <p align="justify">
          <span style="color: #0d0d0d"
            ><span style=""
              ><span style="font-size: large">Payment</span></span
            ></span
          >
        </p>
      </li>
    </ul>
  </ul>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >We use third parties to process payments, handle chargebacks or
          provide billing collection services. When a chargeback is requested
          for your booking arrangement, either by you or by the holder of the
          credit card used to make your reservation, we need to share certain
          reservation details with the payment service provider and the
          relevant financial institution so they can handle the chargeback.
          This may also include a copy of your reservation confirmation, or
          the IP address used to make your reservation. We may share
          information with relevant financial institutions if we consider it
          strictly necessary for fraud detection and prevention
          purposes.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >We share personal data with advertising partners, including your
          email address, as part of marketing MARGKGB TECHNOLOGIES PVT. LTD.
          services via third parties (to ensure that relevant advertisements
          are shown to the right audience). We use techniques such as hashing
          to enable the matching of your email address with an existing
          customer database, so that your email address cannot be used for
          other purposes. For information on other personalised advertisements
          and your choices, please read our cookie policy.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >We use advertising partners, such as metasearch providers, to allow
          you to compare our offers with offers from other Online Travel
          Agencies (OTAs). When you make a reservation on MARGKGB TECHNOLOGIES
          PVT. LTD. after using an advertising partner, we will send the
          details of the reservation that you made on MARGKGB TECHNOLOGIES
          PVT. LTD. to that partner.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >The MARGKGB TECHNOLOGIES PVT. LTD. Services may have ads from third
          parties and links to other websites and apps. Advertising partners
          from outside our site may get information about you when you use
          their services, ads, and content.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >We give ad companies information that helps them show you more
          useful and relevant ads and figure out how well they're doing. When
          we do this, we never give out your name or any other information
          that could be used to find you. We instead use an advertising
          identifier like a cookie, a device identifier, or a code that we get
          from encrypting other information, like an email address, in a way
          that can't be reversed. Even though we don't tell ad companies
          exactly what you searched for, we may give them an advertising
          identifier and an idea of how much the ads they show you are worth
          so they can show you more appropriate and effective ads. You may
          also see ads from other advertisers that are more useful to you from
          some ad companies.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >We disclose personal data to law enforcement to the extent that it
          is required by law or is strictly necessary for the prevention,
          detection or prosecution of criminal acts and fraud, or if we are
          otherwise legally obliged to do so. We may need to further disclose
          personal data to competent authorities to protect and defend our
          rights or properties, or the rights and properties of our business
          partners.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >Business partners: We work with many business partners around the
          world. These business partners distribute and advertise MARGKGB
          TECHNOLOGIES PVT. LTD. services, including the services and products
          of our booking agencies. When you make a reservation on one of our
          business partners&rsquo; websites or apps, certain personal data
          that you give them, such as your name and email address, your
          address, payment details and other relevant information, will be
          forwarded to us to finalise and manage your booking arrangement. If
          customer service is provided by the business partner, MARGKGB
          TECHNOLOGIES PVT. LTD.will share relevant reservation details with
          them (as and when needed) to provide you with appropriate and
          efficient support.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >When you make a reservation through one of our business
          partners&rsquo; websites, the business partners can receive certain
          parts of your personal data related to the specific reservation and
          your interactions on these partner websites. This is for their
          commercial purposes. When you make a reservation on a business
          partners&rsquo; website, please also take the time to read their
          privacy notice if you&rsquo;d like to understand how they process
          your personal data.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >For fraud detection and prevention purposes, we may also exchange
          information about our users with business partners &ndash; but only
          when strictly necessary If an insurance claim is made, concerning
          you and a Booking agency, we may provide the necessary data
          (including personal data) to the insurance company for further
          processing.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >Partner Offer: We may present you with a &lsquo;Partner Offer. When
          you book a stay marked &lsquo;Partner Offer,&rsquo; your reservation
          will be facilitated by a booking agency who is separate from the
          accommodation you&rsquo;re booking. As part of the reservation
          process, we&rsquo;ll need to share some relevant personal data with
          this business partner. If you book a Partner Offer, please review
          the information provided in the booking process or check your
          reservation confirmation for more information about the booking
          agency and how your personal data will be further processed by
          them.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >MARGKGB TECHNOLOGIES PVT. LTD.is a global business. The data that
          we collect from you, as described in this Privacy Statement, could
          be made accessible from, transferred to, or stored in countries
          which may not have the same data protection laws as the country in
          which you initially provided the information. In such cases, we will
          protect your data as described in this Privacy Statement.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >This may also be applicable if you are in the European Economic
          Area (EEA). Countries your data may be transferred to may not have
          laws that provide the same level of protection for your personal
          data as laws within the EEA. Where this is the case, we will put
          appropriate safeguards in place to make sure that these transfers
          comply with European privacy law.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >When your data is transferred to third-party service providers, we
          establish and implement appropriate contractual, organisational, and
          technical measures with them. This is done by putting in place
          Standard Contractual Clauses as approved by the European Commission,
          by examining the countries to which the data may be transferred, and
          by imposing specific technical and organisational security
          measures.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >In certain specific cases, we transfer your data outside the EEA
          because it is in your interest or is necessary to conclude or
          perform the contract, we have with you. For instance, when you make
          a reservation on MARGKGB TECHNOLOGIES PVT. LTD.or through a business
          partner, we might need to transfer your data to a booking agency or
          business partner who is located outside the EEA</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          ><strong
            >How is personal data shared within the MARGKGB TECHNOLOGIES PVT.
            LTD.group?</strong
          ></span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >We may receive personal data about you from other companies or
          share your personal data with them, for the following
          purposes:</span
        ></span
      ></span
    >
  </p>
  <ul>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >To provide services (including to make, administer and manage
              reservations or handle payments);</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >To provide customer service;</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >To detect, prevent and investigate fraudulent, other illegal
              activities and data breaches;</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >For analytical and product improvement purposes;</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >To provide personalised offers or send you marketing with your
              consent or as otherwise permitted by applicable law;</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >For hosting, technical support, overall maintenance and
              maintaining security of such shared data;</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >To ensure compliance with applicable laws;</span
            ></span
          ></span
        >
      </span>
    </li>
  </ul>
  
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >MARGKGB TECHNOLOGIES PVT. LTD.relies, where applicable, on
          compliance with legal obligations (such as lawful law enforcement
          requests).</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          ><strong>INSURANCE SERVICES</strong></span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >MARGKGB TECHNOLOGIES PVT. LTD.may have to use and share personal
          data that is relevant to the insurance product. This data relates to
          you as a potential or actual policyholder, the beneficiaries under a
          policy, family members, claimants and other parties involved in a
          claim.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >To provide offers, arrange insurance cover and handle insurance
          claims, some personal data, provided to us during the booking
          process, (&lsquo;General Order Data&rsquo;) may have to be shared
          with
        </span></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >MARGKGB TECHNOLOGIES PVT. LTD You may also be asked to provide
          additional information, such as names of family members or other
          beneficiaries or details about a claim (&lsquo;Insurance-Specific
          Data&rsquo;).</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >If you make a claim under an insurance policy, this claim may be
          directly handled by the insurer. This means that you may be asked to
          provide personal data to submit the claim directly to them. The
          insurer will inform you accordingly at the point of collection of
          your information. When your claim is handled by the insurer, MARGKGB
          TECHNOLOGIES PVT. LTD.may receive information about the status of
          your claim to provide you with customer support services.</span
        ></span
      ></span
    >
  </p>
  
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          ><strong
            >How we process communications that you and your booked agency may
            send via MARG KGB PVT. LTD.?</strong
          ></span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >MARGKGB TECHNOLOGIES PVT. LTD.can offer you and booking agencies
          several ways to communicate about the trip/stay Services and
          existing booking arrangements, directing the communications via Marg
          KGB Pvt. Ltd.. This also allows you and your booking agency to
          contact MARGKGB TECHNOLOGIES PVT. LTD.with questions about your
          booking arrangement through the website, our apps, and the other
          channels that we make available.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >MARGKGB TECHNOLOGIES PVT. LTD.accesses communications and may use
          automated systems to review, scan, and analyse communications.
        </span></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >We reserve the right to review or block the delivery of
          communications that we, in our sole discretion, believe might
          contain malicious content or spam, or pose a risk to you, booking
          agencies, MARGKGB TECHNOLOGIES PVT. LTD.or others.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >All communications sent or received using MARGKGB TECHNOLOGIES PVT.
          LTD.communication tools will be received and stored by MARGKGB
          TECHNOLOGIES PVT. LTD.Booking agencies and Business partners
          you&rsquo;ve booked a booking arrangement through might also choose
          to communicate with you directly by email or through other channels
          that MARGKGB TECHNOLOGIES PVT. LTD.does not control neither they are
          responsible for any actions taken by third party.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          ><strong>Use of social media by MARG KGB PVT. LTD.</strong></span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >We use it to facilitate the use of online reservation services, but
          also to promote our booking agencies&rsquo; travel-related products
          and services and to advertise, improve and facilitate our own
          services. You are free not to use any of the social media features
          available to you.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >We offer you the opportunity to sign in to a MARGKGB TECHNOLOGIES
          PVT. LTD.user account with one of your social media accounts. After
          you&rsquo;ve signed in once, you&rsquo;ll be able to use your social
          media account to sign into your MARGKGB TECHNOLOGIES PVT.
          LTD.account. You can decouple your MARGKGB TECHNOLOGIES PVT.
          LTD.user account from your chosen social media account any time you
          want to.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d">
      <span style=""
        ><span style="font-size: large"
          >We have also integrated social media plugins into the MARGKGB
          TECHNOLOGIES PVT. LTD.website and apps. This means that when you
          click or tap on one of the buttons (such as Facebook&rsquo;s
          &lsquo;Like&rsquo; button), certain information is shared with these
          social media providers. If you&rsquo;re logged into your social
          media account when you click or tap one of these buttons, your
          social media provider may relate this information to your social
          media account.
        </span></span
      ></span
    >
  </p>
  
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >Your social media provider will be able to tell you more about how
          they use and process your data when you connect to MARGKGB
          TECHNOLOGIES PVT. LTD.through them. This can include combining the
          personal data they collect when you use MARGKGB TECHNOLOGIES PVT.
          LTD.through them with information they collect when you use other
          online platforms you have also linked to your social media
          account.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          ><strong
            >SECURITY AND RETENTION PROCEDURES TO SAFEGUARD PERSONAL
            DATA?</strong
          ></span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >We also use security procedures and technical and physical
          restrictions for accessing and using the personal data on our
          servers. Only authorised personnel are permitted to access personal
          data in the course of their work.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >Personal data can be kept for as long as is necessary to enable us
          to use our services or to provide our services to you (including
          maintaining any MARGKGB TECHNOLOGIES PVT. LTD.user accounts you may
          have), to comply with applicable laws, resolve any disputes and
          otherwise to allow us to conduct our business, including to detect
          and prevent fraud and/or other illegal activities. All personal data
          we keep about you as a MARGKGB TECHNOLOGIES PVT. LTD.customer is
          covered by this Privacy Statement.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >For added protection, we strongly recommend that you set up
          two-factor authentication for your MARGKGB TECHNOLOGIES PVT.
          LTD.user account.
        </span></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >Some of the measures include, but are not limited to:</span
        ></span
      ></span
    >
  </p>
  <ol>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large">Data Encryption</span></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large">Access Controls</span></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large">Data Minimization</span></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large">Employee Training</span></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >Regular Audits and Assessments</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >Incident Response Plan</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large">Data Retention</span></span
          ></span
        >
      </span>
    </li>
  </ol>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          ><strong
            >PERSONAL DATA BELONGING TO CHILDREN UNDER 16 YEARS?</strong
          ></span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >Our services aren&rsquo;t intended for children under 16 years old,
          and we&rsquo;ll never collect their data unless it&rsquo;s provided
          by (and with the consent of) a parent or guardian. Again, this will
          only be used and collected as provided by a parent or guardian and
          with their consent.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >If we become aware that we&rsquo;ve processed the information of a
          child under 16 years old without the valid consent of a parent or
          guardian, we will delete it.</span
        ></span
      ></span
    >
  </p>
  
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          ><strong>GENERAL RIGHTS OF USER</strong></span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >You have the right to information, access, correction, deletion,
          restriction of processing, objection to processing, and data
          portability. If processing is based on your consent, you have the
          right to revoke it at any time.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >We want you to be in control of how your personal data is used by
          us. You can do this in the following ways:</span
        ></span
      ></span
    >
  </p>
  <ul>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >You can ask us for a copy of the personal data we hold about
              you,</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >You can inform us of any changes to your personal data, or you
              can ask us to correct any of the personal data we hold about
              you. As explained below, you can make some of these changes
              yourself, online, when you have a user account,</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >In certain situations, you can ask us to erase, block, or
              restrict the processing of the personal data we hold about you,
              or object to ways in which we are using your personal
              data,</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >In certain situations, you can also ask us to send the personal
              data you have given us to a third party,</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >Where we are using your personal data based on your consent,
              you are entitled to withdraw that consent at any time subject to
              applicable law, and</span
            ></span
          ></span
        >
      </span>
    </li>
    <li style="margin-bottom:10px;">
      <span align="justify">
        <span style="color: #0d0d0d"
          ><span style=""
            ><span style="font-size: large"
              >Where we process your personal data based on legitimate
              interest or the public interest, you have the right to object to
              that use of your personal data at any time, subject to
              applicable law.</span
            ></span
          ></span
        >
      </span>
    </li>
  </ul>
  
  <p align="justify">
    <span style=""
      ><span style="font-size: large"
        ><strong
          ><span style="color: #0d0d0d">HOW TO CONTACT US</span></strong
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >MARGKGB TECHNOLOGIES PVT. LTD. has its helpline number 8200983800
          and helpdesk ID INFO@MARGKGB.COM__________________ for any
          grievance.
        </span></span
      ></span
    >
  </p>
  
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >Our cookie statement may also be updated from time to time. If
          these updates are substantial, particularly relevant to you or
          impact your data protection rights, we&rsquo;ll contact you about
          them. However, we recommend that you visit this page regularly to
          stay up to date with any other (less substantial or relevant)
          updates.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style="color: #0d0d0d"
      ><span style=""
        ><span style="font-size: large"
          >We reserve the rights to revise the Privacy Policy from time to
          time to suit various legal, business and customer requirement. We
          will duly notify the users as may be necessary.</span
        ></span
      ></span
    >
  </p>
  <p align="justify">
    <span style=""
      ><span style="font-size: large"
        ><strong
          ><span style="color: #0d0d0d"
            >You may always submit concerns regarding this Privacy Policy via
            email to us at&nbsp;</span
          ></strong
        ></span
      ></span
    ><span style=""
      ><span style="font-size: large"
        ><strong
          ><a href="mailto:__INFO@MARGKGB.COM_________________"
            >__INFO@MARGKGB.COM_________________</a
          ></strong
        ></span
      ></span
    ><span style=""
      ><span style="font-size: large"
        ><strong><span style="color: #0d0d0d">. </span></strong></span
      ></span
    >
  </p>
  <p align="justify">
    <span style=""
      ><span style="font-size: large"
        ><strong
          ><span style="color: #0d0d0d"
            >MARGKGB TECHNOLOGIES PVT. LTD.shall endeavour to respond to all
            reasonable concerns and inquires.</span
          ></strong
        ></span
      ></span
    >
  </p>
  `,
  TNC: `<p align="justify">
  <span style="color: #333333"
    ><span style=""
      ><span style="font-size: large"
        ><strong>TERMS AND CONDITIONS</strong></span
      ></span
    ></span
  >
</p>
<p align="justify">
  <span style="color: #333333"
    ><span style=""
      ><span style="font-size: large"
        >MARGKGB TECHNOLOGIES PVT.LTD. Believe in serving our users
        precisely by adhering to what is good and beneficial for them. We
        try to protect them with legal obligations and terms while choosing
        us as their service provider. We hereby request our customers and
        their representatives to check below terms and conditions before
        confirming or making payment to MARGKGB TECHNOLOGIES PVT.LTD.. Below
        are the rules and policies applicable to all reservations unless
        specified by our booking agent in writing. We request you to check
        the itinerary date, travel sector, name as per adhaar card/passport
        or any other relevant documents properly.</span
      ></span
    ></span
  >
</p>
<ol>
  <li style="margin-bottom:10px;">
    <span align="justify">
      <span style="color: #333333"
        ><span style=""
          ><span style="font-size: large"
            >Name of the user must be the same as per the passport or other
            relevant ID proofs.</span
          ></span
        ></span
      >
    </span>
  </li>
  <li style="margin-bottom:10px;">
    <span align="justify">
      <span style="color: #333333"
        ><span style=""
          ><span style="font-size: large"
            >During and prior to darshan we will not be liable for any
            information or guidance received by you on conclusive nature. We
            are the connectors of darshanarthi to the priests and pujaris.
            We are not liable on any information received by you on our
            behalf. We do not take any responsibility of any information
            given by any third party as well.</span
          ></span
        ></span
      >
    </span>
  </li>
  <li style="margin-bottom:10px;">
    <span align="justify">
      <span style="color: #333333"
        ><span style=""
          ><span style="font-size: large"
            >There shall be no responsibility of our company regarding any
            bus or cab services, even any local transport shall be arranged
            by you. We are not liable neither responsible for any kind of
            misfortune if occurs during your stay and travel.</span
          ></span
        ></span
      >
    </span>
  </li>
  <li style="margin-bottom:10px;">
    <span align="justify">
      <span style="color: #333333"
        ><span style=""
          ><span style="font-size: large"
            >We shall not be liable for any food services. We will duly
            recommend you the best, even bookings can be done but we shall
            stand free of any liability that may arose while and after
            availing the services at the restaurants or eateries.</span
          ></span
        ></span
      >
    </span>
  </li>
  <li style="margin-bottom:10px;">
    <span align="justify">
      <span style="color: #333333"
        ><span style=""
          ><span style="font-size: large"
            >We shall not stand liable for any shopping done by you during
            your stay. Any lost item shall not bound us for any kind of
            reimbursements. Shop at your own risk.</span
          ></span
        ></span
      >
    </span>
  </li>
  <li style="margin-bottom:10px;">
    <span align="justify">
      <span style="color: #333333"
        ><span style=""
          ><span style="font-size: large"
            >We do not give any assurance or belief on any information
            provided by us. We are not liable for any communications between
            us and third party as well.</span
          ></span
        ></span
      >
    </span>
  </li>
  <li style="margin-bottom:10px;">
    <span align="justify">
      <span style="color: #333333"
        ><span style=""
          ><span style="font-size: large"
            >Any commitments or services offered by us such as Mandir
            darshan, pooja aarti, prasad, pandit ji connect for pooja and
            aarti and other VVIP darshans shall be booked prior and it shall
            be according to the terms and conditions of the pujari and
            mandir staff. We shall take bookings according to the
            availability of the timeslots. We are not liable for any kind of
            mishaps or contingencies occurred during the special days and
            events at the temple. There shall be no such commitments from
            our side on those special occasions. We are not liable for any
            unforeseen event that may hinder pooja, VVIP darshan, normal
            darshan, pandit ji seva, prasads etc. Any information provided
            by us shall not stand conclusive.
          </span></span
        ></span
      >
    </span>
  </li>
  <li style="margin-bottom:10px;">
    <span align="justify">
      <span style="color: #333333"
        ><span style=""
          ><span style="font-size: large"
            >Any prior booking might get cancelled due to special events and
            that amount will not be refunded to the user. The user shall
            bear risk of such events.</span
          ></span
        ></span
      >
    </span>
  </li>
  <li style="margin-bottom:10px;">
    <span align="justify">
      <span style="color: #333333"
        ><span style=""
          ><span style="font-size: large"
            >Passport must be valid for 6 months from date of departure (on
            return date also).</span
          ></span
        ></span
      ><span style="color: #333333"
        ><span style=""
          ><span style="font-size: medium"> </span></span></span
      ><span style="color: #333333"
        ><span style=""
          ><span style="font-size: large"
            >MARGKGB TECHNOLOGIES PVT.LTD. bears no visa obligations or
            responsibility, so request customers to check requirements
            before travel.</span
          ></span
        ></span
      >
    </span>
  </li>
  <li style="margin-bottom:10px;">
    <span align="justify">
      <span style="color: #333333"
        ><span style=""
          ><span style="font-size: large"
            >Their shall be no personalised events conducted in the name of
            VVIP darshans. It shall be the duty of the user to respect the
            sensitivity of the temple and harmonise their stay and darshan
            according to the timings of the mandir.</span
          ></span
        ></span
      >
    </span>
  </li>
  <li style="margin-bottom:10px;">
    <span align="justify">
      <span style="color: #333333"
        ><span style=""
          ><span style="font-size: large"
            >We shall not be liable for any frauds conducted with our name.
            you shall trust the representatives of MARGKGB TECHNOLOGIES
            PVT.LTD. with whom you are corresponding.</span
          ></span
        ></span
      >
    </span>
  </li>
  <li style="margin-bottom:10px;">
    <span align="justify">
      <span style="color: #333333"
        ><span style=""
          ><span style="font-size: large"
            >The passport must be valid for 6 months from the date of
            departure. MARGKGB TECHNOLOGIES PVT.LTD. bears no visa
            obligations or responsibility, so request customers to check
            requirements before travel.
          </span></span
        ></span
      >
    </span>
  </li>
  <li style="margin-bottom:10px;">
    <span align="justify">
      <span style="color: #333333"
        ><span style=""
          ><span style="font-size: large"
            >The advised fares are guaranteed for the same day or fare
            expiry, whichever is earlier (sometimes airlines change fare
            without notice and in that case, MARGKGB TECHNOLOGIES PVT.LTD.
            will not bear any loss). All bookings will attract the
            applicable taxes which will not be reimbursed by us. We are not
            liable for any costs incurred while booking through our portal.
          </span></span
        ></span
      >
    </span>
  </li>
  <li style="margin-bottom:10px;">
    <span align="justify">
      <span style="color: #333333"
        ><span style=""
          ><span style="font-size: large"
            >MARGKGB TECHNOLOGIES PVT.LTD. is not liable for any schedule
            change or cancellation of flight operations by the airline. In
            case of a schedule change by the Airline (International and
            Domestic), there will be admin fees applicable in addition to
            any cancellation fee of the airline (if the airline gives a
            waiver for refund or changes). We are not liable to pay, refund
            or return any of the costs of bookings. We request customers to
            contact the airline prior to departure to ensure there is no
            change to the scheduled departure time.
          </span></span
        ></span
      >
    </span>
  </li>
  <li style="margin-bottom:10px;">
    <span align="justify">
      <span style="color: #333333"
        ><span style=""
          ><span style="font-size: large"
            >Any special services such as extra meals, tickets, change in
            bookings, change in itinerary etc shall be requested within 48
            hours. Any complimentary service is not guaranteed as it is
            subject to availability.</span
          ></span
        ></span
      ><span style="color: #333333"
        ><span style=""
          ><span style="font-size: medium"> </span></span></span
      ><span style="color: #333333"
        ><span style=""
          ><span style="font-size: large"
            >If you are traveling with combination fares of two or more
            airlines, our travel agency is not responsible for any flight
            delays. Please also check the baggage allowance with your travel
            consultant, we are not liable for the same.
          </span></span
        ></span
      >
    </span>
  </li>
  <li style="margin-bottom:10px;">
    <span align="justify">
      <span style="color: #333333"
        ><span style=""
          ><span style="font-size: large"
            >Users are responsible for ensuring they have the necessary
            travel documents, such as passports and visas. It will not be
            our liability if any documents or luggage is lost while
            travelling and during your stays. We also recommend travellers
            to purchase travel insurance to protect you from unforeseen
            circumstances throughout your journey. As we are not liable for
            any kind of mishap or unforeseen event that occur during your
            stays. It will cover potential risks such as trip cancellations,
            medical emergencies, or lost luggage.</span
          ></span
        ></span
      >
      <span style="color: #333333"
        ><span style=""
          ><span style="font-size: large"
            >We shall not be liable in case of accidents or injuries during
            the tour.
          </span></span
        ></span
      >
    </span>
  </li>
  <li style="margin-bottom:10px;">
    <span align="justify">
      <span style="color: #333333"
        ><span style=""
          ><span style="font-size: large"
            >We act as an agent for and sell various travel related products
            as agents on behalf of, numerous transport, accommodation, and
            other service providers, such as airlines, coach, rail, and
            cruise line operators, as well as all of our wholesalers. Any
            services we provide to you are collateral to that agency
            relationship. Our obligation to you is to (and you expressly
            authorize us to) make travel bookings on your behalf and to
            arrange relevant contracts between you and travel service
            providers. We exercise care in the selection of reputable
            service providers, but we are not ourselves a provider of travel
            services and have no control over, or liability for, the
            services provided by third parties.</span
          ></span
        ></span
      >
    </span>
  </li>
  <li style="margin-bottom:10px;">
    <span align="justify">
      <span style="color: #333333"
        ><span style=""
          ><span style="font-size: large"
            >Any taxes or charges imposed by the government or airport
            authorities are payable by passengers. In case of
            cancellation/refund many taxes are non-refundable.</span
          ></span
        ></span
      >
    </span>
  </li>
  <li style="margin-bottom:10px;">
    <span align="justify">
      <span style="color: #333333"
        ><span style=""
          ><span style="font-size: large"
            >Credit Card Transactions If for any reason, any travel service
            provider is unable to provide the services for which you have
            contracted, your remedy lies against the provider, and not
            against MARGKGB TECHNOLOGIES PVT.LTD., and in that event that
            payment has been made to MARGKGB TECHNOLOGIES PVT.LTD. by credit
            card, you agree that you will not seek to chargeback your
            payment from MARGKGB TECHNOLOGIES PVT.LTD..</span
          ></span
        ></span
      ><span style="color: #333333"
        ><span style=""
          ><span style="font-size: medium"> </span></span></span
      ><span style="color: #333333"
        ><span style=""
          ><span style="font-size: large"
            >In case of chargeback, the customer is liable to pay money to
            MARGKGB TECHNOLOGIES PVT.LTD. and claim money directly from the
            merchant/operator.</span
          ></span
        ></span
      ><span style="color: #333333"
        ><span style=""
          ><span style="font-size: medium"> </span></span></span
      ><span style="color: #333333"
        ><span style=""
          ><span style="font-size: large"
            >In case of any refund/changes CC surcharge is
            non-refundable.</span
          ></span
        ></span
      >
    </span>
  </li>
  <li style="margin-bottom:10px;">
    <span align="justify">
      <span style="color: #333333"
        ><span style=""
          ><span style="font-size: large"
            >As we are working as an intermediary between service provider
            and customer. Airlines process the refund to
            consolidator/wholesalers/ customer credit card not direct to
            MARGKGB TECHNOLOGIES PVT.LTD. account. Once MARGKGB TECHNOLOGIES
            PVT.LTD. receives a refund from consolidator/wholesalers, we
            need 2 working weeks to process the refund to the customer
            account.</span
          ></span
        ></span
      ><span style="color: #333333"
        ><span style=""
          ><span style="font-size: medium"> </span></span></span
      ><span style="color: #333333"
        ><span style=""
          ><span style="font-size: large"
            >In case a refund is given in credit by the
            consolidator/wholesalers, then MARGKGB TECHNOLOGIES PVT.LTD. is
            not liable to give a refund to the customer in bank
            account/cash. Eco travel will provide some form of payment which
            is provided by the airline or supplier.</span
          ></span
        ></span
      >
    </span>
  </li>
  <li style="margin-bottom:10px;">
    <span align="justify">
      <span style="color: #333333"
        ><span style=""
          ><span style="font-size: large"
            >It's important for the user to carefully read and understand
            the terms and conditions before booking a tour to ensure they
            are aware of their rights and responsibilities during the
            trip.</span
          ></span
        ></span
      >
    </span>
  </li>
  <li style="margin-bottom:10px;">
    <span align="justify">
      <span style="color: #333333"
        ><span style=""
          ><span style="font-size: large"
            >MARGKGB TECHNOLOGIES PVT.LTD. may terminate or suspend a user's
            access to the service anytime by giving intimation if any such
            reason is observed during the service if any illegal or illicit
            activity is observed by the service providers or any third
            party. MARGKGB TECHNOLOGIES PVT.LTD. shall not be liable for any
            misconduct done by the user.
          </span></span
        ></span
      >
    </span>
  </li>
  <li style="margin-bottom:10px;">
    <span align="justify">
      <span style="color: #333333"
        ><span style=""
          ><span style="font-size: large"
            >Online service providers typically include a link to their
            privacy policy, which outlines how user data is collected, used,
            and protected. This may include information about data
            collection methods, cookies, and third-party sharing practices.
            For that the reference shall be made to the website of the
            company.</span
          ></span
        ></span
      >
    </span>
  </li>
  <li style="margin-bottom:10px;">
    <span align="justify">
      <span style="color: #333333"
        ><span style=""
          ><span style="font-size: large"
            >If the tour includes any intellectual property, such as
            photographs or videos taken by the tour operator, this section
            may specify how these materials can be used by the travellers
            and users and any restrictions on their distribution or
            reproduction.</span
          ></span
        ></span
      >
    </span>
  </li>
  <li style="margin-bottom:10px;">
    <span align="justify">
      <span style="color: #333333"
        ><span style=""
          ><span style="font-size: large"
            >The terms and conditions can be modified at any time with or
            without prior notice to users.</span
          ></span
        ></span
      >
    </span>
  </li>
  <li style="margin-bottom:10px;">
    <span align="justify">
      <span style="color: #333333"
        ><span style=""
          ><span style="font-size: large"
            >We are not responsible if anyone wants money to use our name
            and services.</span
          ></span
        ></span
      >
    </span>
  </li>
  <li style="margin-bottom:10px;">
    <span align="justify">
      <span style="color: #333333"
        ><span style=""
          ><span style="font-size: large"
            >We are not responsible if anyone deny services after your call
            and connect with him. We will provide only contact details with
            name.
          </span></span
        ></span
      >
    </span>
  </li>
</ol> 
<p align="justify">
  <span style="color: #333333"
    ><span style=""
      ><span style="font-size: large"><strong>NOTE:</strong></span></span
    ></span
  ><span style="color: #333333"
    ><span style=""><span style="font-size: large"> </span></span></span
  ><span style="color: #333333"
    ><span style=""
      ><span style="font-size: large"
        ><strong
          >Do not make any payment or accept any booking unless you
          understand and agree with our terms and conditions. Your
          ticket/service will only be issued once you have acknowledged and
          agreed to these terms and conditions.</strong
        ></span
      ></span
    ></span
  >
</p>`,
};

export const STATIC_TYPE = {
  privacy: "PRIVACY",
  tnc: "TNC",
};

export const LANGUAGE = {
  en: "en",
  hi: "hi",
};
