import styled from "styled-components";

const StaticPageWrapper = styled.div`
  .h1 .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  .a,
  .p,
  .div,
  .body {
    font-family: "urbanist-regular";
  }
  p{
    margin-bottom: 15px;
  }
  ul {
    list-style-position: inside;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  a {
    color: #007bff;
  }

  ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 15px;
  }
  li p{
    display: inline-block;  
  }
  li::marker{
    margin: 1px 
  }
`;

export default StaticPageWrapper;